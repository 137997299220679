import React, {Component} from "react";
import {
    MDBCollapse,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler
} from "mdb-react-ui-kit";
import {MDBCol, MDBRow} from "mdbreact";
import {FaMessage} from "react-icons/fa6";
import {FaLanguage, FaTelegramPlane, FaTextHeight, FaWeight} from "react-icons/fa";
import {ToastContainer} from "react-toastify";
import {BrowserView, MobileView} from "react-device-detect";
import RightSideNavbar from "./rightSideNavbar";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";
import {useNavigate, useParams} from "react-router";
import {connect} from "react-redux";
import {Chip, Icon} from "@mui/material";
import {GiDoorRingHandle} from "react-icons/gi";
import {MdHeight, MdMonitorWeight} from "react-icons/md";
import {IoLanguageOutline} from "react-icons/io5";


class CandidateProfile extends Component{

    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            profile: null,
            data: null,
            isIntro: localStorage.getItem('intro'),
            lang: this.props.params.lang,

        }
    }

    chooseLang() {

        if(this.state.lang === 'fa'){
            localStorage.setItem("lang", this.state.lang);
        }else {
            if(this.state.lang === 'en'){
                localStorage.setItem("lang", this.state.lang);
            }else {
                if(localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'fa');
                }
            }
        }

        var l = localStorage.getItem("lang")
        this.languageChangeListener = () =>{
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {
        this.chooseLang()
        this.getUserProfile()
        this.getData()
    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://uharmonymatch.com/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if(response.status === 200) {
                    localStorage.setItem('profile', JSON.stringify({
                        is_registration_complete: response.data.is_registration_complete,
                        is_seen_videos: response.data.is_seen_videos,
                        is_verified: response.data.is_verified
                    }));
                    this.setState({
                        profile: response.data
                    });
                }

            })
            .catch((error) => {


            });
    }

    getData(){
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id', null);

        var lang = localStorage.getItem("lang")
        if(lang == null){
            lang = 'fa'
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://uharmonymatch.com/api/v1/user/'+id+'/?lang='+lang, requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if(response.status === 200) {
                    this.setState({
                        data: response.data
                    });
                }

            })
            .catch((error) => {


            });
    }

    mobileView(){

        const { setLanguage } = this.context;

        const changeLangBTN = (newLanguage) => {
            localStorage.setItem("lang", newLanguage);
            setLanguage(newLanguage);
            window.location.reload()
        };


        if(this.props.i18n.language === 'fa') {
            return (
                <body className="text-right bg-body" dir="rtl">

                <MDBNavbar expand='lg' light bgColor='light' className={'p-3 mb-3 text-end'} style={{direction: 'ltr'}}>

                    <MDBNavbarBrand href='/'>
                        <img
                            src='/logo192.png'
                            height='30'
                            alt='کانون هدایت'
                            loading='lazy'
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => this.setState({open: !this.state.open})}
                    >
                        <MDBIcon icon='bars' fas/>
                    </MDBNavbarToggler>

                    <MDBCollapse navbar show={this.state.open}>

                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                            <MDBNavbarItem>
                                <MDBNavbarLink href='/my-profile'
                                               style={{fontWeight: 'bold'}}>
                                    پروفایل من
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink href='/learning' style={{fontWeight: 'bold'}}>آموزش</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink active aria-current='page' href='/users' style={{fontWeight: 'bold'}}>لیست
                                    کاربران</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/' style={{fontWeight: 'bold'}}>صفحه اصلی</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/logout' style={{fontWeight: 'bold'}}>خروج</MDBNavbarLink>
                            </MDBNavbarItem>


                            <div className={'mt-3'}>
                                <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                                <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                            </div>

                        </MDBNavbarNav>


                    </MDBCollapse>

                </MDBNavbar>

                <MDBRow className={'m-0'}
                        style={{
                            minHeight: '31px',
                        }}>

                    <div className={'mt-5 text-left'}>

                        <button className="btn" style={{background: '#FF686B', color: '#fff', fontSize: '12px', fontWeight: 'bold'}}
                                onClick={(v) => this.chatBTN(v)}>
                            <label><FaMessage/> {this.props.i18n.t('conversion_btn_title')}</label>
                        </button>
                    </div>
                </MDBRow>

                <div className={'container-fluid'}>


                    <MDBRow className={'mt-5 text-center'}>
                        <div>
                            <img height={'150'} style={{borderRadius: '10%'}} src={this.state.data.image}/>
                        </div>
                        <label
                            style={{fontWeight: 'bold', fontSize: '15px', color: 'black', marginTop: '30px'}}>
                            {this.state.data.name} / {this.state.data.birthday}
                        </label>

                    </MDBRow>

                    <div className={'mt-5 text-end'}>
                        <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                            {this.props.i18n.t('Bio')}
                        </label>
                    </div>
                    <MDBRow className={'mt-1 text-left'}>
                        <p
                            className={'ltr text-start'}
                            style={{
                                textAlign: 'justify',
                                color: '#000000',
                            }}>
                            {this.state.data.about_me}
                        </p>
                    </MDBRow>

                    <div className={'mt-5 text-end'}>
                        <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                            {this.props.i18n.t('nationally_and_region')}
                        </label>
                    </div>
                    <MDBRow className={'mt-1'}>
                        <div>
                            <Chip
                                avatar={<MDBIcon flag={this.state.data.nationality.flag} className={'m-2'}/>}
                                label={this.state.data.nationality.name}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />

                            <Chip label={this.state.data.religious} variant="outlined" className={'m-1'}/>
                            <Chip label={this.state.data.religious_type} variant="outlined" className={'m-1'}/>
                        </div>
                    </MDBRow>

                    <div className={'mt-5 text-end'}>
                        <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                            {this.props.i18n.t('education_and_career')}
                        </label>
                    </div>
                    <MDBRow className={'mt-1'}>
                        <div>
                            <Chip
                                avatar={<MDBIcon fas icon="user-graduate" className={'mt-2'}
                                                 style={{fontSize: '15px', marginRight: '14px'}}/>}
                                label={this.state.data.degree}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />

                            <Chip
                                avatar={<MDBIcon fas icon="book-open" className={'mt-2'}
                                                 style={{fontSize: '15px', marginRight: '14px'}}/>}
                                label={this.state.data.major}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />

                            <Chip
                                avatar={<MDBIcon fas icon="briefcase" className={'mt-2'}
                                                 style={{fontSize: '15px', marginRight: '14px'}}/>}
                                label={this.state.data.occupation}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />

                        </div>
                    </MDBRow>

                    <div className={'mt-5 text-end'}>
                        <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                            {this.props.i18n.t('about_me')}
                        </label>
                    </div>
                    <MDBRow className={'mt-1'}>
                        <div>
                            <Chip
                                avatar={<MDBIcon fas icon="map-marker" className={'mt-2'}
                                                 style={{fontSize: '15px', marginRight: '14px'}}/>}
                                label={this.state.data.city_of_live + ', '+ this.state.data.country_of_live}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />

                            <Chip
                                avatar={<GiDoorRingHandle style={{fontSize: '15px', marginRight: '10px'}}/>}
                                label={this.state.data.martial_status}
                                variant="outlined"
                                size="medium"
                                className={'m-2'}
                            />

                            <Chip
                                avatar={<MdHeight style={{fontSize: '15px', marginRight: '10px'}}/>}
                                label={this.state.data.height}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />
                            <Chip
                                avatar={<MdMonitorWeight style={{fontSize: '15px', marginRight: '10px'}}/>}
                                label={this.state.data.weight}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />

                            {this.state.data.languages.map((item, index) => (
                                <Chip
                                    avatar={<IoLanguageOutline style={{fontSize: '15px', marginRight: '10px'}}/>}
                                    label={item}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />
                            ))}


                        </div>
                    </MDBRow>

                    <div className={'mt-5 text-end'}>
                        <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                            {this.props.i18n.t('Interest')}
                        </label>
                    </div>
                    <MDBRow className={'mt-1'}>
                        <div>

                            {this.state.data.interests.map((item, index) => (
                                <Chip
                                    label={item}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />
                            ))}

                        </div>
                    </MDBRow>

                    <div className={'mt-5 text-end'}>
                        <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                            {this.props.i18n.t('Personality')}
                        </label>
                    </div>
                    <MDBRow className={'mt-1 mb-5'}>
                        <div>

                            {this.state.data.personalities.map((item, index) => (
                                <Chip
                                    label={item}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />
                            ))}

                        </div>
                    </MDBRow>

                    <MDBRow className={'mt-1 mb-5'}>
                    </MDBRow>


                </div>
                </body>
            )
        } else {
            return (
                <body className="text-right bg-body" dir="ltr">
                <div className={'container-fluid'}>
                    <MDBNavbar expand='lg' light bgColor='light' className={'p-3 mb-3 text-start'}
                               style={{direction: 'ltr'}}>
                        <MDBNavbarBrand href='/'>
                            <img
                                src='/logo192.png'
                                height='30'
                                alt='کانون هدایت'
                                loading='lazy'
                            />
                        </MDBNavbarBrand>
                        <MDBNavbarToggler
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => this.setState({open: !this.state.open})}
                        >
                            <MDBIcon icon='bars' fas/>
                        </MDBNavbarToggler>

                        <MDBCollapse navbar show={this.state.open}>

                            <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                                <MDBNavbarItem>
                                    <MDBNavbarLink href='/my-profile' style={{fontWeight: 'bold'}}>
                                        {this.props.i18n.t('my_profile')}
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink href='/learning'
                                                   style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                                </MDBNavbarItem>

                                <MDBNavbarItem>
                                    <MDBNavbarLink active aria-current='page' href='/users'
                                                   style={{fontWeight: 'bold'}}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                                </MDBNavbarItem>

                                <MDBNavbarItem>
                                    <MDBNavbarLink href='/'
                                                   style={{fontWeight: 'bold'}}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                                </MDBNavbarItem>

                                <MDBNavbarItem>
                                    <MDBNavbarLink href='/logout'
                                                   style={{fontWeight: 'bold'}}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                                </MDBNavbarItem>

                                <div className={'mt-3'}>
                                    <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                                    <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                                </div>

                            </MDBNavbarNav>


                        </MDBCollapse>

                    </MDBNavbar>

                    <MDBRow className={'m-0'}
                            style={{
                                minHeight: '31px',
                            }}>

                        <div className={'mt-5 text-left'}>

                            <button className="btn" style={{background: '#FF686B', color: '#fff', fontSize: '12px', fontWeight: 'bold'}}
                                    onClick={(v) => this.chatBTN(v)}>
                                <label><FaMessage/> {this.props.i18n.t('conversion_btn_title')}</label>
                            </button>
                        </div>
                    </MDBRow>

                    <MDBRow className={'mt-5 text-center'}>
                        <div>
                            <img height={'160'} style={{borderRadius: '10%'}} src={this.state.data.image}/>
                        </div>
                        <label
                            style={{fontWeight: 'bold', fontSize: '15px', color: 'black', marginTop: '30px'}}>
                            {this.state.data.name} / {this.state.data.birthday}
                        </label>

                    </MDBRow>

                    <div className={'mt-5 text-start'}>
                        <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                            {this.props.i18n.t('Bio')}
                        </label>
                    </div>
                    <MDBRow className={'mt-1 text-left'}>
                        <p
                            className={'ltr text-start'}
                            style={{
                                textAlign: 'justify',
                                color: '#000000',
                            }}>
                            {this.state.data.about_me}
                        </p>
                    </MDBRow>

                    <div className={'mt-5 text-start'}>
                        <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                            {this.props.i18n.t('nationally_and_region')}
                        </label>
                    </div>
                    <MDBRow className={'mt-1'}>
                        <div>
                            <Chip
                                avatar={<MDBIcon flag={this.state.data.nationality.flag} className={'m-2'}/>}
                                label={this.state.data.nationality.name}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />

                            <Chip label={this.state.data.religious} variant="outlined" className={'m-1'}/>
                            <Chip label={this.state.data.religious_type} variant="outlined" className={'m-1'}/>
                        </div>
                    </MDBRow>

                    <div className={'mt-5 text-start'}>
                        <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                            {this.props.i18n.t('education_and_career')}
                        </label>
                    </div>
                    <MDBRow className={'mt-1'}>
                        <div>
                            <Chip
                                avatar={<MDBIcon fas icon="user-graduate" className={'mt-2'}
                                                 style={{fontSize: '15px', marginLeft: '14px'}}/>}
                                label={this.state.data.degree}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />

                            <Chip
                                avatar={<MDBIcon fas icon="book-open" className={'mt-2'}
                                                 style={{fontSize: '15px', marginLeft: '14px'}}/>}
                                label={this.state.data.major}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />

                            <Chip
                                avatar={<MDBIcon fas icon="briefcase" className={'mt-2'}
                                                 style={{fontSize: '15px', marginLeft: '14px'}}/>}
                                label={this.state.data.occupation}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />

                        </div>
                    </MDBRow>

                    <div className={'mt-5 text-start'}>
                        <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                            {this.props.i18n.t('about_me')}
                        </label>
                    </div>
                    <MDBRow className={'mt-1'}>
                        <div>
                            <Chip
                                avatar={<MDBIcon fas icon="map-marker" className={'mt-2'}
                                                 style={{fontSize: '15px', marginLeft: '14px'}}/>}
                                label={this.state.data.city_of_live + ', '+ this.state.data.country_of_live}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />


                            <Chip
                                avatar={<GiDoorRingHandle/>}
                                label={this.state.data.martial_status}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />

                            <Chip
                                avatar={<MdHeight/>}
                                label={this.state.data.height}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />
                            <Chip
                                avatar={<MdMonitorWeight/>}
                                label={this.state.data.weight}
                                variant="outlined"
                                size="medium"
                                className={'m-1'}
                            />

                            {this.state.data.languages.map((item, index) => (
                                <Chip
                                    avatar={<MdMonitorWeight/>}
                                    label={item}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />
                            ))}


                        </div>
                    </MDBRow>

                    <div className={'mt-5 text-start'}>
                        <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                            {this.props.i18n.t('Interest')}
                        </label>
                    </div>
                    <MDBRow className={'mt-1'}>
                        <div>

                            {this.state.data.interests.map((item, index) => (
                                <Chip
                                    label={item}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />
                            ))}

                        </div>
                    </MDBRow>

                    <div className={'mt-5 text-start'}>
                        <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                            {this.props.i18n.t('Personality')}
                        </label>
                    </div>
                    <MDBRow className={'mt-1 mb-5'}>
                        <div>

                            {this.state.data.personalities.map((item, index) => (
                                <Chip
                                    label={item}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />
                            ))}

                        </div>
                    </MDBRow>

                    <MDBRow className={'mt-1 mb-5'}>
                    </MDBRow>
                </div>
                </body>
            )

        }
    }

    desktopView() {
        if (this.props.i18n.language === 'fa') {
            return (
                <body className="text-right bg-body rtl" dir="rtl" style={{height: "100vh"}}>
                <div className={'container-fluid'}>
                    <RightSideNavbar
                        profile={this.state.profile}
                        page={'candidate-list'}
                    />

                    <div style={{marginRight: '300px', padding: '0px 10px'}}>


                        <MDBRow className={'m-0'} style={{
                            minHeight: '30%',

                        }}>

                            <MDBCol className={'align-items-end justify-content-end text-left d-flex'}>

                                <div className={'text-right mt-5'}>
                                    <button className="btn" style={{background: '#FF686B', color: '#fff'}}
                                            onClick={(v) => this.chatBTN(v)}>
                                        <label><FaMessage/> {this.props.i18n.t('conversion_btn_title')}</label>
                                    </button>
                                </div>

                            </MDBCol>
                        </MDBRow>

                        <MDBRow className={'mt-5 text-center'}>
                            <div>
                                <img height={'160'} style={{borderRadius: '10%'}} src={this.state.data.image}/>
                            </div>
                            <label
                                style={{fontWeight: 'bold', fontSize: '20px', color: 'black', marginTop: '30px'}}>
                                {this.state.data.name} / {this.state.data.birthday}
                            </label>

                        </MDBRow>

                        <div className={'mt-5 text-end'}>
                            <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                                {this.props.i18n.t('Bio')}
                            </label>
                        </div>
                        <MDBRow className={'mt-1 text-left'}>
                            <p
                                className={'ltr text-start'}
                                style={{
                                    textAlign: 'justify',
                                    color: '#000000',
                                }}>
                                {this.state.data.about_me}
                            </p>
                        </MDBRow>

                        <div className={'mt-5 text-end'}>
                            <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                                {this.props.i18n.t('nationally_and_region')}
                            </label>
                        </div>
                        <MDBRow className={'mt-1'}>
                            <div>
                                <Chip
                                    avatar={<MDBIcon flag={this.state.data.nationality.flag} className={'m-2'}/>}
                                    label={this.state.data.nationality.name}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />

                                <Chip label={this.state.data.religious} variant="outlined" className={'m-1'}/>
                                <Chip label={this.state.data.religious_type} variant="outlined" className={'m-1'}/>
                            </div>
                        </MDBRow>

                        <div className={'mt-5 text-end'}>
                            <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                                {this.props.i18n.t('education_and_career')}
                            </label>
                        </div>
                        <MDBRow className={'mt-1'}>
                            <div>
                                <Chip
                                    avatar={<MDBIcon fas icon="user-graduate" className={'mt-2'}
                                                     style={{fontSize: '15px', marginRight: '14px'}}/>}
                                    label={this.state.data.degree}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />

                                <Chip
                                    avatar={<MDBIcon fas icon="book-open" className={'mt-2'}
                                                     style={{fontSize: '15px', marginRight: '14px'}}/>}
                                    label={this.state.data.major}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />

                                <Chip
                                    avatar={<MDBIcon fas icon="briefcase" className={'mt-2'}
                                                     style={{fontSize: '15px', marginRight: '14px'}}/>}
                                    label={this.state.data.occupation}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />

                            </div>
                        </MDBRow>

                        <div className={'mt-5 text-end'}>
                            <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                                {this.props.i18n.t('about_me')}
                            </label>
                        </div>
                        <MDBRow className={'mt-1'}>
                            <div>
                                <Chip
                                    avatar={<MDBIcon fas icon="map-marker" className={'mt-2'}
                                                     style={{fontSize: '15px', marginRight: '14px'}}/>}
                                    label={this.state.data.city_of_live + ', '+ this.state.data.country_of_live}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />

                                <Chip
                                    avatar={<GiDoorRingHandle style={{fontSize: '15px', marginRight: '10px'}}/>}
                                    label={this.state.data.martial_status}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-2'}
                                />

                                <Chip
                                    avatar={<MdHeight style={{fontSize: '15px', marginRight: '10px'}}/>}
                                    label={this.state.data.height}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />
                                <Chip
                                    avatar={<MdMonitorWeight style={{fontSize: '15px', marginRight: '10px'}}/>}
                                    label={this.state.data.weight}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />

                                {this.state.data.languages.map((item, index) => (
                                    <Chip
                                        avatar={<IoLanguageOutline style={{fontSize: '15px', marginRight: '10px'}}/>}
                                        label={item}
                                        variant="outlined"
                                        size="medium"
                                        className={'m-1'}
                                    />
                                ))}


                            </div>
                        </MDBRow>

                        <div className={'mt-5 text-end'}>
                            <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                                {this.props.i18n.t('Interest')}
                            </label>
                        </div>
                        <MDBRow className={'mt-1'}>
                            <div>

                                {this.state.data.interests.map((item, index) => (
                                    <Chip
                                        label={item}
                                        variant="outlined"
                                        size="medium"
                                        className={'m-1'}
                                    />
                                ))}

                            </div>
                        </MDBRow>

                        <div className={'mt-5 text-end'}>
                            <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                                {this.props.i18n.t('Personality')}
                            </label>
                        </div>
                        <MDBRow className={'mt-1 mb-5'}>
                            <div>

                                {this.state.data.personalities.map((item, index) => (
                                    <Chip
                                        label={item}
                                        variant="outlined"
                                        size="medium"
                                        className={'m-1'}
                                    />
                                ))}

                            </div>
                        </MDBRow>

                        <MDBRow className={'mt-1 mb-5'}>
                        </MDBRow>

                    </div>

                </div>
                </body>


            )
        } else {
            return (
                <body className="text-right bg-body ltr" dir="ltr" style={{height: "100vh"}}>
                <div className={'container-fluid'}>
                    <RightSideNavbar
                        profile={this.state.profile}
                        page={'candidate-list'}
                    />
                    <div style={{marginLeft: '300px', padding: '0px 10px'}}>


                        <MDBRow className={'m-0'} style={{
                            minHeight: '30%',

                        }}>

                            <MDBCol className={'align-items-end justify-content-end text-left d-flex'}>

                                <div className={'text-right mt-5'}>
                                    <button className="btn" style={{background: '#FF686B', color: '#fff'}}
                                            onClick={(v) => this.chatBTN(v)}>
                                        <label><FaMessage/> {this.props.i18n.t('conversion_btn_title')}</label>
                                    </button>
                                </div>

                            </MDBCol>
                        </MDBRow>

                        <MDBRow className={'mt-5 text-center'}>
                            <div>
                                <img height={'160'} style={{borderRadius: '10%'}} src={this.state.data.image}/>
                            </div>
                            <label
                                style={{fontWeight: 'bold', fontSize: '20px', color: 'black', marginTop: '30px'}}>
                                {this.state.data.name} / {this.state.data.birthday}
                            </label>

                        </MDBRow>

                        <div className={'mt-5 text-start'}>
                            <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                                {this.props.i18n.t('Bio')}
                            </label>
                        </div>
                        <MDBRow className={'mt-1 text-left'}>
                            <p
                                className={'ltr text-start'}
                                style={{
                                    textAlign: 'justify',
                                    color: '#000000',
                                }}>
                                {this.state.data.about_me}
                            </p>
                        </MDBRow>

                        <div className={'mt-5 text-start'}>
                            <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                                {this.props.i18n.t('nationally_and_region')}
                            </label>
                        </div>
                        <MDBRow className={'mt-1'}>
                            <div>
                                <Chip
                                    avatar={<MDBIcon flag={this.state.data.nationality.flag} className={'m-2'}/>}
                                    label={this.state.data.nationality.name}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />

                                <Chip label={this.state.data.religious} variant="outlined" className={'m-1'}/>
                                <Chip label={this.state.data.religious_type} variant="outlined" className={'m-1'}/>
                            </div>
                        </MDBRow>

                        <div className={'mt-5 text-start'}>
                            <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                                {this.props.i18n.t('education_and_career')}
                            </label>
                        </div>
                        <MDBRow className={'mt-1'}>
                            <div>
                                <Chip
                                    avatar={<MDBIcon fas icon="user-graduate" className={'mt-2'}
                                                     style={{fontSize: '15px', marginLeft: '14px'}}/>}
                                    label={this.state.data.degree}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />

                                <Chip
                                    avatar={<MDBIcon fas icon="book-open" className={'mt-2'}
                                                     style={{fontSize: '15px', marginLeft: '14px'}}/>}
                                    label={this.state.data.major}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />

                                <Chip
                                    avatar={<MDBIcon fas icon="briefcase" className={'mt-2'}
                                                     style={{fontSize: '15px', marginLeft: '14px'}}/>}
                                    label={this.state.data.occupation}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />

                            </div>
                        </MDBRow>

                        <div className={'mt-5 text-start'}>
                            <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                                {this.props.i18n.t('about_me')}
                            </label>
                        </div>
                        <MDBRow className={'mt-1'}>
                            <div>
                                <Chip
                                    avatar={<MDBIcon fas icon="map-marker" className={'mt-2'}
                                                     style={{fontSize: '15px', marginLeft: '14px'}}/>}
                                    label={this.state.data.city_of_live + ', '+ this.state.data.country_of_live}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />

                                <Chip
                                    avatar={<GiDoorRingHandle/>}
                                    label={this.state.data.martial_status}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />

                                <Chip
                                    avatar={<MdHeight/>}
                                    label={this.state.data.height}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />
                                <Chip
                                    avatar={<MdMonitorWeight/>}
                                    label={this.state.data.weight}
                                    variant="outlined"
                                    size="medium"
                                    className={'m-1'}
                                />

                                {this.state.data.languages.map((item, index) => (
                                    <Chip
                                        avatar={<MdMonitorWeight/>}
                                        label={item}
                                        variant="outlined"
                                        size="medium"
                                        className={'m-1'}
                                    />
                                ))}


                            </div>
                        </MDBRow>

                        <div className={'mt-5 text-start'}>
                            <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                                {this.props.i18n.t('Interest')}
                            </label>
                        </div>
                        <MDBRow className={'mt-1'}>
                            <div>

                                {this.state.data.interests.map((item, index) => (
                                    <Chip
                                        label={item}
                                        variant="outlined"
                                        size="medium"
                                        className={'m-1'}
                                    />
                                ))}

                            </div>
                        </MDBRow>

                        <div className={'mt-5 text-start'}>
                            <label style={{fontWeight: 'bold', fontSize: '18px', color: 'black'}}>
                                {this.props.i18n.t('Personality')}
                            </label>
                        </div>
                        <MDBRow className={'mt-1 mb-5'}>
                            <div>

                                {this.state.data.personalities.map((item, index) => (
                                    <Chip
                                        label={item}
                                        variant="outlined"
                                        size="medium"
                                        className={'m-1'}
                                    />
                                ))}

                            </div>
                        </MDBRow>

                        <MDBRow className={'mt-1 mb-5'}>
                        </MDBRow>

                    </div>

                </div>
                </body>
            )
        }
    }

    render() {
        if (localStorage.getItem("token") === null) {
            return (
                <div style={{fontWeight: 'bold', fontSize: '25px', direction: 'ltr'}}>
                    Forbidden!
                </div>
            );
        }

        if (this.state.profile !== null && this.state.data !== null) {
            return (
                <div>
                    <ToastContainer/>
                    <BrowserView>
                        {this.desktopView()}
                    </BrowserView>
                    <MobileView>
                        {this.mobileView()}
                    </MobileView>
                </div>
            );
        } else {
            return (
                <div>

                </div>
            );
        }

    }

    startConversation(st) {
        window.location.href = '/chatroom?isDirect=true&candidateAvatar=' + this.state.data.image + '&candidateID=' + this.state.data.id + '&candidateName=' + this.state.data.name
    }

    chatBTN(st) {
        // window.location.href = '/chatroom'
        window.location.href = '/chatroom?isDirect=true&candidateAvatar=' + this.state.data.image + '&candidateID=' + this.state.data.id + '&candidateName=' + this.state.data.name
    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()}/>;
}

export default connect()(withParams(withTranslation()(CandidateProfile)));
