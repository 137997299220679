import React, {Component} from "react";
import Nav from "./NavBar";
import {MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBRow} from "mdbreact";
import Footer from "./footer";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";
import {useParams} from "react-router";
import {connect} from "react-redux";


class Logout extends Component{

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        localStorage.removeItem('token')
        localStorage.removeItem('profile')

        this.state ={
            lang: this.props.params.lang,
        }
    }

    chooseLang() {

        if(this.state.lang === 'fa'){
            localStorage.setItem("lang", this.state.lang);
        }else {
            if(this.state.lang === 'en'){
                localStorage.setItem("lang", this.state.lang);
            }else {
                if(localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'fa');
                }
            }
        }

        var l = localStorage.getItem("lang")


        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {
        this.chooseLang()
    }

    render() {

        return(
            <body className="text-right bg-body" dir="rtl">
            <Nav
                page='logout'
            />
            <MDBRow className="mt-5 pt-4"></MDBRow>
            <MDBRow className="justify-content-center align-items-center h-100 text-center m-1" >
                <MDBCard alighment="center">
                    <MDBCardBody>
                        <MDBCardTitle></MDBCardTitle>
                        <MDBCardText>
                            {this.props.i18n.t('sign_out_msg')}
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBRow>
            <Footer/>
            </body>
        )
    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export default connect()(withParams(withTranslation()(Logout)));
