import React, {Component} from "react";
import {
    MDBCollapse,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler
} from "mdb-react-ui-kit";
import IntroPage from "./IntroPage";
import {MDBCard, MDBCol, MDBRow} from "mdbreact";
import {Menu, menuClasses, MenuItem, Sidebar} from "react-pro-sidebar";
import {Link} from "react-router-dom";
import {FaArrowLeft, FaArrowRight, FaSignOutAlt, FaUser} from "react-icons/fa";
import {toast, ToastContainer} from "react-toastify";
import {BrowserView, MobileView} from "react-device-detect";
import YouTube from "react-youtube";
import NotAllowedPage from "./NotAllowedPage";
import {Button} from "@mui/material";
import Swal from "sweetalert2";
import RightSideNavbar from "./rightSideNavbar";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";
import {useParams} from "react-router";
import {connect} from "react-redux";


class VideoLearningPage extends Component{

    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            profile: null,
            isIntro: localStorage.getItem('intro'),
            state: 1,
            isVideoOneSeen: false,
            isVideoTwoSeen: false,
            isVideoThreeSeen: false,
            isVideoFourSeen: false,
            isVideoFiveSeen: false,
            lang: this.props.params.lang,

        }

    }

    chooseLang() {
        if(this.state.lang === 'fa'){
            localStorage.setItem("lang", this.state.lang);
        }else {
            if(this.state.lang === 'en'){
                localStorage.setItem("lang", this.state.lang);
            }else {
                if(localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'fa');
                }
            }
        }

        var l = localStorage.getItem("lang")
        this.languageChangeListener = () =>{
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {
        this.chooseLang()
        this.getUserProfile()
    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://uharmonymatch.com/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if(response.status === 200) {
                    localStorage.setItem('profile', JSON.stringify({
                        is_registration_complete: response.data.is_registration_complete,
                        is_seen_videos: response.data.is_seen_videos,
                        is_verified: response.data.is_verified
                    }));
                    this.setState({
                        profile: response.data
                    });
                }

            })
            .catch((error) => {


            });
    }

    _onReady(event) {
        event.target.pauseVideo();
    }

    _onEnd(event, video_number) {
        switch (video_number){
            case 1:{
                this.setState({
                    isVideoOneSeen: true,
                    isVideoTwoSeen: false,
                    isVideoThreeSeen: false,
                    isVideoFourSeen: false,
                    isVideoFiveSeen: false
                })
            }
                break

            case 2:{
                this.setState({
                    isVideoOneSeen: false,
                    isVideoTwoSeen: true,
                    isVideoThreeSeen: false,
                    isVideoFourSeen: false,
                    isVideoFiveSeen: false
                })
            }
                break

            case 3:{
                this.setState({
                    isVideoOneSeen: false,
                    isVideoTwoSeen: false,
                    isVideoThreeSeen: true,
                    isVideoFourSeen: false,
                    isVideoFiveSeen: false
                })
            }
                break

            case 4:{
                this.sentCompletionRequest()
                this.setState({
                    isVideoOneSeen: false,
                    isVideoTwoSeen: false,
                    isVideoThreeSeen: false,
                    isVideoFourSeen: true,
                    isVideoFiveSeen: false
                })
            }
                break

            case 5:{
                this.sentCompletionRequest()
                this.setState({
                    isVideoOneSeen: false,
                    isVideoTwoSeen: false,
                    isVideoThreeSeen: false,
                    isVideoFourSeen: false,
                    isVideoFiveSeen: true
                })

                Swal.fire({
                    title: this.props.i18n.t('notice'),
                    text: this.props.t('watched_videos_msg'),
                    icon: 'success',
                    confirmButtonText: this.props.t('ok_alert_msg')
                }).then(function () {
                    window.location.href = "/learning";
                })
            }
                break
        }
    }

    sentCompletionRequest(){
        var profiledata = JSON.parse(localStorage.getItem('profile'))
        if(!profiledata.is_seen_videos) {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ localStorage.getItem('token')
                },
            };

            fetch('https://uharmonymatch.com/api/v1/update-learning/', requestOptions)
                .then((response) => {
                    if(!response.ok) throw response.status;
                    else return response.json();
                })
                .then((response) => {
                    if(response.status === 202) {
                        localStorage.setItem('profile', JSON.stringify({
                            is_registration_complete: profiledata.is_registration_complete,
                            is_seen_videos: true,
                            is_verified: profiledata.is_verified
                        }));
                    }

                })
                .catch((error) => {


                });
        }
    }

    mobileView(){

        const { setLanguage } = this.context;

        const changeLangBTN = (newLanguage) => {
            localStorage.setItem("lang", newLanguage);
            setLanguage(newLanguage);
            window.location.reload()
        };

        if(this.props.i18n.language === 'fa'){
            if(this.state.isIntro == null || this.state.isIntro == false){
                return (
                    <body className="text-right bg-body" dir="rtl" style = {{height:"100vh"}}>
                    <div className={'container-fluid'}>
                        <MDBNavbar expand='lg' light bgColor='light' className={'p-3'} style={{direction: 'ltr'}}>

                            <MDBNavbarBrand href='/'>
                                <img
                                    src='/logo192.png'
                                    height='30'
                                    alt='کانون هدایت'
                                    loading='lazy'
                                />
                            </MDBNavbarBrand>

                            <MDBNavbarToggler
                                aria-controls='navbarSupportedContent'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={() => this.setState({open: !this.state.open})}
                            >
                                <MDBIcon icon='bars' fas />
                            </MDBNavbarToggler>

                            <MDBCollapse navbar show={this.state.open}>

                                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink active aria-current='page' href='/my-profile' style={{fontWeight: 'bold'}}>
                                            {this.props.i18n.t('my_profile')}
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/learning' style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/users' style={{fontWeight: 'bold'}}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/' style={{fontWeight: 'bold'}}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/logout' style={{fontWeight: 'bold'}}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                                    </MDBNavbarItem>


                                    <div className={'mt-3'}>
                                        <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                                        <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                                    </div>

                                </MDBNavbarNav>


                            </MDBCollapse>

                        </MDBNavbar>

                        <IntroPage/>

                    </div>
                    </body>
                )
            }
            return(
                <body className="text-right bg-body" dir="rtl">

                <MDBNavbar expand='lg' light bgColor='light' className={'p-3 mb-0 text-end'} style={{direction: 'ltr'}}>

                    <MDBNavbarBrand href='/'>
                        <img
                            src='/logo192.png'
                            height='30'
                            alt='کانون هدایت'
                            loading='lazy'
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => this.setState({open: !this.state.open})}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>

                    <MDBCollapse navbar show={this.state.open}>

                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                            <MDBNavbarItem>
                                <MDBNavbarLink aria-current='page' href='/my-profile' style={{fontWeight: 'bold'}}>
                                    {this.props.i18n.t('my_profile')}
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink  active href='/learning' style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/users' style={{fontWeight: 'bold'}}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/' style={{fontWeight: 'bold'}}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/logout' style={{fontWeight: 'bold'}}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                            </MDBNavbarItem>


                            <div className={'mt-3'}>
                                <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                                <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                            </div>

                        </MDBNavbarNav>


                    </MDBCollapse>

                </MDBNavbar>
                <div className={'container-fluid'} style=
                    {{
                        height: '100vh',
                        backgroundImage: `url(/images/videoLbg.png)`,
                        backgroundSize: 'auto 100%',
                        backgroundAttachment: 'fixed',
                        backgroundPosition: 'fit'
                    }}>
                    {this.getStep(true)}
                </div>
                </body>
            )
        }else {
            if(this.state.isIntro == null || this.state.isIntro == false){
                return (
                    <body className="text-right bg-body" dir="rtl" style = {{height:"100vh"}}>
                    <div className={'container-fluid'}>
                        <MDBNavbar expand='lg' light bgColor='light' className={'p-3'} style={{direction: 'ltr'}}>

                            <MDBNavbarBrand href='/'>
                                <img
                                    src='/logo192.png'
                                    height='30'
                                    alt='کانون هدایت'
                                    loading='lazy'
                                />
                            </MDBNavbarBrand>

                            <MDBNavbarToggler
                                aria-controls='navbarSupportedContent'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={() => this.setState({open: !this.state.open})}
                            >
                                <MDBIcon icon='bars' fas />
                            </MDBNavbarToggler>

                            <MDBCollapse navbar show={this.state.open}>

                                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink active aria-current='page' href='/my-profile' style={{fontWeight: 'bold'}}>
                                            {this.props.i18n.t('my_profile')}
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/learning' style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/users' style={{fontWeight: 'bold'}}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/' style={{fontWeight: 'bold'}}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/logout' style={{fontWeight: 'bold'}}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                                    </MDBNavbarItem>


                                    <div className={'mt-3'}>
                                        <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                                        <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                                    </div>

                                </MDBNavbarNav>


                            </MDBCollapse>

                        </MDBNavbar>

                        <IntroPage/>

                    </div>
                    </body>
                )
            }
            return(
                <body className="text-right bg-body" dir="rtl">

                <MDBNavbar expand='lg' light bgColor='light' className={'p-3 mb-0'} style={{direction: 'ltr'}}>

                    <MDBNavbarBrand href='/'>
                        <img
                            src='/logo192.png'
                            height='30'
                            alt='کانون هدایت'
                            loading='lazy'
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => this.setState({open: !this.state.open})}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>

                    <MDBCollapse navbar show={this.state.open}>

                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                            <MDBNavbarItem>
                                <MDBNavbarLink aria-current='page' href='/my-profile' style={{fontWeight: 'bold'}}>
                                    {this.props.i18n.t('my_profile')}
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink  active href='/learning' style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/users' style={{fontWeight: 'bold'}}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/' style={{fontWeight: 'bold'}}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/logout' style={{fontWeight: 'bold'}}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                            </MDBNavbarItem>


                            <div className={'mt-3'}>
                                <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                                <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                            </div>

                        </MDBNavbarNav>


                    </MDBCollapse>

                </MDBNavbar>
                <div className={'container-fluid'} style=
                    {{
                        height: '100vh',
                        backgroundImage: `url(/images/videoLbg.png)`,
                        backgroundSize: 'auto 100%',
                        backgroundAttachment: 'fixed',
                        backgroundPosition: 'fit'
                    }}>
                    {this.getStep(true)}
                </div>
                </body>
            )
        }

    }

    desktopView(){
        if(this.props.i18n.language === 'fa'){
            if(this.state.isIntro == null || this.state.isIntro == false){
                return (
                    <body className="text-right bg-body" dir="rtl" style = {{height:"100vh"}}>



                    <div className={'container-fluid'}>
                        <RightSideNavbar
                            profile={this.state.profile}
                            page={'my-profile'}
                        />

                        <IntroPage/>

                    </div>
                    </body>
                )
            }


            return(
                <body className="text-right bg-body" dir="rtl" style = {{height:"100vh"}}>
                <div className={'container-fluid'}>
                    <RightSideNavbar
                        profile={this.state.profile}
                        page={'my-profile'}
                    />

                    <MDBRow style={{
                        backgroundImage: `url(/images/videoLbg.png)`,
                        backgroundSize: 'auto 100%',
                        backgroundAttachment: 'fixed',

                        backgroundPosition: 'fit'}}>

                        <MDBCol className={'p-4 mt-5'} style={{
                            marginRight: '300px', padding: '0px 10px'
                            }}>

                            {this.getStep(false)}
                        </MDBCol>
                    </MDBRow>

                </div>
                </body>
            )
        }else {
            if(this.state.isIntro == null || this.state.isIntro == false){
                return (
                    <body className="text-left bg-body ltr" dir="rtl" style={{height: "100vh", backgroundColor: '#355070'}}>
                    <div className={'container-fluid'}>
                        <RightSideNavbar
                            profile={this.state.profile}
                            page={'my-profile'}
                        />

                        <div style={{marginLeft: '250px', padding: '0px 10px',  backgroundColor: '#355070'}}>
                        <IntroPage/>
                        </div>

                    </div>
                    </body>
                )
            }


            return(
                <body className="text-right bg-body" dir="rtl" style = {{height:"100vh"}}>
                <div className={'container-fluid'}>
                    <MDBRow>
                        <MDBCol size={"auto"} className={'p-0'}>
                            <div style={{ display: 'flex', height: '100%', minHeight: '400px'}} className={'text-center'}>
                                <MDBCard className={'shadow-6-strong'}>
                                    <Sidebar style={{height:"100vh"}} backgroundColor="#FFFFFF">
                                        <Menu>
                                            <div style={{marginBottom: '50px'}}>
                                                <MDBRow>
                                                    <div className={"text-center"}>

                                                        <img height={'100'} style={{borderRadius: '70%', marginTop: '90px', marginBottom: '20px'}} src={this.state.profile.picture}/>
                                                    </div>
                                                </MDBRow>
                                                <MDBRow>
                                                    <div className={"text-center"}>
                                                        {this.state.profile.first_name} {this.state.profile.last_name}
                                                    </div>
                                                </MDBRow>
                                            </div>

                                            <MenuItem component={<Link to="/my-profile"/>}> {this.props.i18n.t('my_profile')} </MenuItem>
                                            <MenuItem rootStyles={{
                                                ['.' + menuClasses.button]: {
                                                    backgroundColor: '#BEE3DB',
                                                    color: '#BEE3DB',
                                                    '&:hover': {
                                                        backgroundColor: '#BEE3DB',
                                                    },
                                                },
                                            }} component={<Link to="/learning"/>}> {this.props.i18n.t('learning')} </MenuItem>
                                            <MenuItem component={<Link to="/users"/>}> {this.props.i18n.t('users_list')} </MenuItem>
                                            <div className={'mt-lg-5'}></div>

                                            <MenuItem component={<Link to="/"/>}>{this.props.i18n.t('home_page')}</MenuItem>
                                            <MenuItem component={<Link to="/logout"/>} >{this.props.i18n.t('sign_out')}</MenuItem>

                                        </Menu>

                                    </Sidebar >
                                </MDBCard>
                            </div>
                        </MDBCol>
                        <MDBCol className={'p-4'} style={{
                            backgroundImage: `url(/images/videoLbg.png)`,
                            backgroundSize: 'auto 100%',
                            backgroundAttachment: 'fixed',
                            backgroundPosition: 'fit'}}>

                            {this.getStep(false)}
                        </MDBCol>
                    </MDBRow>
                </div>
                </body>
            )
        }
    }

    render() {
        if(localStorage.getItem("token") === null){
            return(
                <div style={{fontWeight: 'bold', fontSize: '25px', direction: 'ltr'}}>
                    Forbidden!
                </div>
            );
        }

        if(this.state.profile !== null) {
            return (
                <div>
                    <ToastContainer/>
                    <BrowserView>
                        {this.desktopView()}
                    </BrowserView>
                    <MobileView>
                        {this.mobileView()}
                    </MobileView>
                </div>
            );
        }else {
            return(
                <div>

                </div>
            );
        }

    }

    getStep(isMobile){
        if(this.props.i18n.language === 'en'){
            if (isMobile) {
                const opts = {
                    height: '230',
                    width: '300',
                    playerVars: {
                        // https://developers.google.com/youtube/player_parameters
                        autoplay: 1,
                    },
                };
                if (this.state.state === 1) {
                    return (
                        <div>
                            <MDBRow>
                                <div className={'text-center mt-5'}>
                                    <img src={'/images/step1.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-1 mt-4'}>
                                    <YouTube videoId="eni3M0-fpXo" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 1)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-1'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "6px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '30%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '30%',
                                                }
                                            }}
                                        >
                                            <label><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>

                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    );
                }

                if (this.state.state === 2) {
                    return (
                        <div>
                            <MDBRow>
                                <div className={'text-center mt-5'}>
                                    <img src={'/images/step2.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-1 mt-4'}>
                                    <YouTube videoId="BFj0B-pIFes" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 2)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-1'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "6px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '45%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '45%',
                                                }
                                            }}
                                        >
                                            <label><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                                <MDBCol className={'align-self-start align-items-start text-start mt-1'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "6px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '45%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '45%',
                                                }
                                            }}
                                        >
                                            <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '2px'}}/></label>
                                        </Button>

                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }

                if (this.state.state === 3) {
                    return (
                        <div>
                            <MDBRow>
                                <div className={'text-center mt-5'}>
                                    <img src={'/images/step3.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-1 mt-4'}>
                                    <YouTube videoId="BSXGhT35HiQ" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 3)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-1'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "6px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '45%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '45%',
                                                }
                                            }}
                                        >
                                            <label><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                                <MDBCol className={'align-self-start align-items-start text-start mt-1'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "6px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '45%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '45%',
                                                }
                                            }}
                                        >
                                            <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '2px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }

                if (this.state.state === 4) {
                    return (
                        <div>
                            <MDBRow>
                                <div className={'text-center mt-5'}>
                                    <img src={'/images/step4.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-1 mt-4'}>

                                    <YouTube videoId="yOlx6aLCwu8" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 4)}/>
                                </div>


                                <MDBCol className={'align-self-center mt-1'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "6px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '45%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '45%',
                                                }
                                            }}
                                        >
                                            <label><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                                <MDBCol className={'align-self-start align-items-start text-start mt-1'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "6px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '45%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '45%',
                                                }
                                            }}
                                        >
                                            <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '2px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }

                if (this.state.state === 5) {
                    return (
                        <div>
                            <MDBRow>
                                <div className={'text-center mt-5'}>
                                    <img src={'/images/step5.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-1 mt-4'}>

                                    <YouTube videoId="ZGQUexTs6Js" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 5)}/>
                                </div>


                                <MDBCol className={'align-self-start align-items-start text-start mt-1'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "6px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '45%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '45%',
                                                }
                                            }}
                                        >
                                            <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '2px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }
            } else {
                const opts = {
                    height: '390',
                    width: '640',
                    playerVars: {
                        // https://developers.google.com/youtube/player_parameters
                        autoplay: 1,
                    },
                };
                if (this.state.state === 1) {
                    return (
                        <div>
                            <MDBRow className={'mt-5'}>
                                <div className={'text-center mt-4'}>
                                    <img width={'58%'} src={'/images/step1.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-4 mt-4'}>
                                    <YouTube videoId="eni3M0-fpXo" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 1)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-4'}>
                                    <div className={'text-right p-4'}>

                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "11px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '9.5%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '9.5%',
                                                }
                                            }}
                                        >
                                            <label><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    );
                }

                if (this.state.state === 2) {
                    return (
                        <div>
                            <MDBRow className={'mt-5'}>
                                <div className={'text-center mt-4'}>
                                    <img width={'58%'} src={'/images/step2.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-4 mt-4'}>
                                    <YouTube videoId="BFj0B-pIFes" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 2)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-4'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "11px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '20%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '20%',
                                                }
                                            }}
                                        >
                                            <label><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                                <MDBCol className={'align-self-start align-items-start text-start mt-4'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "11px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '20%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '20%',
                                                }
                                            }}
                                        >
                                            <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '4px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }

                if (this.state.state === 3) {
                    return (
                        <div>
                            <MDBRow className={'mt-5'}>
                                <div className={'text-center mt-4'}>
                                    <img width={'58%'} src={'/images/step3.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-4 mt-4'}>
                                    <YouTube videoId="BSXGhT35HiQ" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 3)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-4'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "11px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '20%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '20%',
                                                }
                                            }}
                                        >
                                            <label><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                                <MDBCol className={'align-self-start align-items-start text-start mt-4'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "11px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '20%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '20%',
                                                }
                                            }}
                                        >
                                            <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '4px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }

                if (this.state.state === 4) {
                    return (
                        <div>
                            <MDBRow className={'mt-5'}>
                                <div className={'text-center mt-4'}>
                                    <img width={'58%'} src={'/images/step3.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-4 mt-4'}>
                                    <YouTube videoId="yOlx6aLCwu8" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 4)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-4'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "11px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '20%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '20%',
                                                }
                                            }}
                                        >
                                            <label><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                                <MDBCol className={'align-self-start align-items-start text-start mt-4'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "11px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '20%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '20%',
                                                }
                                            }}
                                        >
                                            <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '4px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }

                if (this.state.state === 5) {
                    return (
                        <div>
                            <MDBRow className={'mt-5'}>
                                <div className={'text-center mt-4'}>
                                    <img width={'58%'} src={'/images/step5.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-4 mt-4'}>
                                    <YouTube videoId="ZGQUexTs6Js" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 5)}/>
                                </div>


                                <MDBCol className={'align-self-start align-items-start text-start mt-4'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "11px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '9.5%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '9.5%',
                                                }
                                            }}
                                        >
                                            <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '4px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }
            }
        }else { //FA
            if (isMobile) {
                const opts = {
                    height: '230',
                    width: '300',
                    playerVars: {
                        // https://developers.google.com/youtube/player_parameters
                        autoplay: 1,
                    },
                };
                if (this.state.state === 1) {
                    return (
                        <div>
                            <MDBRow>
                                <div className={'text-center mt-5'}>
                                    <img src={'/images/step1.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-1 mt-4'}>
                                    <YouTube videoId="B5fBvRS42sU" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 1)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-1'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "8px",
                                                fontFamily: 'IRANSansX',
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '15%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '15%',
                                                }
                                            }}
                                        >
                                            <label><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>

                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    );
                }

                if (this.state.state === 2) {
                    return (
                        <div>
                            <MDBRow>
                                <div className={'text-center mt-5'}>
                                    <img src={'/images/step2.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-1 mt-4'}>
                                    <YouTube videoId="wNJEkFxQSW0" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 2)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-1'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "8px",
                                                fontFamily: 'IRANSansX',
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '15%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '15%',
                                                }
                                            }}
                                        >
                                            <label><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                                <MDBCol className={'align-self-start align-items-start text-start mt-1'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "8px",
                                                fontFamily: 'IRANSansX',
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '15%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '15%',
                                                }
                                            }}
                                        >
                                            <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '2px'}}/></label>
                                        </Button>

                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }

                if (this.state.state === 3) {
                    return (
                        <div>
                            <MDBRow>
                                <div className={'text-center mt-5'}>
                                    <img src={'/images/step3.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-1 mt-4'}>
                                    <YouTube videoId="ci2h_jlrunU" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 3)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-1'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "8px",
                                                fontFamily: 'IRANSansX',
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '15%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '15%',
                                                }
                                            }}
                                        >
                                            <label><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                                <MDBCol className={'align-self-start align-items-start text-start mt-1'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "8px",
                                                fontFamily: 'IRANSansX',
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '15%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '15%',
                                                }
                                            }}
                                        >
                                            <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '2px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }

                if (this.state.state === 4) {
                    return (
                        <div>
                            <MDBRow>
                                <div className={'text-center mt-5'}>
                                    <img src={'/images/step4.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-1 mt-4'}>
                                    <YouTube videoId="9UNmGiiJ1YQ" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 4)}/>
                                </div>


                                <MDBCol className={'align-self-center mt-1'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "8px",
                                                fontFamily: 'IRANSansX',
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '15%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '15%',
                                                }
                                            }}
                                        >
                                            <label><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                                <MDBCol className={'align-self-start align-items-start text-start mt-1'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "8px",
                                                fontFamily: 'IRANSansX',
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '15%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '15%',
                                                }
                                            }}
                                        >
                                            <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '2px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }

                if (this.state.state === 5) {
                    return (
                        <div>
                            <MDBRow>
                                <div className={'text-center mt-5'}>
                                    <img src={'/images/step5.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-1 mt-4'}>
                                    <YouTube videoId="dul4PzkD6S0" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 5)}/>
                                </div>


                                <MDBCol className={'align-self-start align-items-start text-start mt-1'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontSize: "8px",
                                                fontFamily: 'IRANSansX',
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '15%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '15%',
                                                }
                                            }}
                                        >
                                            <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '2px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }
            } else {
                const opts = {
                    height: '390',
                    width: '640',
                    playerVars: {
                        // https://developers.google.com/youtube/player_parameters
                        autoplay: 1,
                    },
                };
                if (this.state.state === 1) {
                    return (
                        <div>
                            <MDBRow className={'mt-5'}>
                                <div className={'text-center mt-2'}>
                                    <img width={'58%'} src={'/images/step1.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-4 mt-4'}>
                                    <YouTube videoId="B5fBvRS42sU" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 1)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-4'}>
                                    <div className={'text-right p-4'} style={{fontFamily: 'IRANSansX'}}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "13px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '9.5%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '9.5%',
                                                }
                                            }}
                                        >
                                            <label style={{fontFamily: 'IRANSansX'}}><FaArrowRight
                                                style={{marginLeft: '4px', fontFamily: 'IRANSansX'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    );
                }

                if (this.state.state === 2) {
                    return (
                        <div>
                            <MDBRow className={'mt-5'}>
                                <div className={'text-center mt-2'}>
                                    <img width={'58%'} src={'/images/step2.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-4 mt-4'}>
                                    <YouTube videoId="wNJEkFxQSW0" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 2)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-4'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "13px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '20%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '20%',
                                                }
                                            }}
                                        >
                                            <label style={{fontFamily: 'IRANSansX'}}><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                                <MDBCol className={'align-self-start align-items-start text-start mt-4'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "13px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '20%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '20%',
                                                }
                                            }}
                                        >
                                            <label style={{fontFamily: 'IRANSansX'}}> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '4px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }

                if (this.state.state === 3) {
                    return (
                        <div>
                            <MDBRow className={'mt-5'}>
                                <div className={'text-center mt-2'}>
                                    <img width={'58%'} src={'/images/step3.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-4 mt-4'}>
                                    <YouTube videoId="ci2h_jlrunU" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 3)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-4'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "13px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '20%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '20%',
                                                }
                                            }}
                                        >
                                            <label style={{fontFamily: 'IRANSansX'}}><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                                <MDBCol className={'align-self-start align-items-start text-start mt-4'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "13px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '20%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '20%',
                                                }
                                            }}
                                        >
                                            <label style={{fontFamily: 'IRANSansX'}}> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '4px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }

                if (this.state.state === 4) {
                    return (
                        <div>
                            <MDBRow className={'mt-5'}>
                                <div className={'text-center mt-2'}>
                                    <img width={'58%'} src={'/images/step4.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-4 mt-4'}>
                                    <YouTube videoId="9UNmGiiJ1YQ" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 4)}/>
                                </div>

                                <MDBCol className={'align-self-center mt-4'}>
                                    <div className={'text-right p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.nextPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "13px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '20%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '20%',
                                                }
                                            }}
                                        >
                                            <label style={{fontFamily: 'IRANSansX'}}><FaArrowRight
                                                style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}
                                            </label>
                                        </Button>
                                    </div>
                                </MDBCol>
                                <MDBCol className={'align-self-start align-items-start text-start mt-4'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "13px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '20%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '20%',
                                                }
                                            }}
                                        >
                                            <label style={{fontFamily: 'IRANSansX'}}> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '4px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }

                if (this.state.state === 5) {
                    return (
                        <div>
                            <MDBRow className={'mt-5'}>
                                <div className={'text-center mt-2'}>
                                    <img width={'58%'} src={'/images/step5.png'} className={'img-fluid'}/>
                                </div>
                            </MDBRow>
                            <MDBRow>
                                <div className={'text-center mb-4 mt-4'}>
                                    <YouTube videoId="dul4PzkD6S0" opts={opts} onReady={this._onReady}
                                             onEnd={(v) => this._onEnd(v, 5)}/>
                                </div>


                                <MDBCol className={'align-self-start align-items-start text-start mt-4'}>
                                    <div className={'text-left p-4'}>
                                        <Button
                                            className={'p-2'}
                                            onClick={(v) => this.previousPage(v)}
                                            variant="contained"
                                            style={{fontFamily: 'IRANSansX'}}
                                            sx={{
                                                border: 1,
                                                boxShadow: 0,
                                                backgroundColor: "#FFF",
                                                fontFamily: 'IRANSansX',
                                                fontSize: "13px",
                                                background: 'none',
                                                color: "#fff",
                                                borderRadius: '5px',
                                                width: '9.5%',
                                                ":hover": {
                                                    bgcolor: "#BEE3DB",
                                                    color: "#000000",
                                                    boxShadow: 0,
                                                    width: '9.5%',
                                                }
                                            }}
                                        >
                                            <label style={{fontFamily: 'IRANSansX'}}> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft
                                                style={{marginRight: '4px'}}/></label>
                                        </Button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    );
                }
            }
        }
    }

    nextPage(st){

        if(
            (this.state.state === 1 && this.state.isVideoOneSeen) ||
            (this.state.state === 2 && this.state.isVideoTwoSeen) ||
            (this.state.state === 3 && this.state.isVideoThreeSeen) ||
            (this.state.state === 4 && this.state.isVideoFourSeen) ||
            (this.state.state === 5 && this.state.isVideoFiveSeen)
        ) {
            this.setState({
                state: this.state.state + 1
            })
        }else {

            toast.info(this.props.i18n.t('watch_video_completely_toast'), {
                position: toast.POSITION.TOP_LEFT
            });
        }
    }

    previousPage(st){
        this.setState({
            state: this.state.state-1
        })
    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export default connect()(withParams(withTranslation()(VideoLearningPage)));