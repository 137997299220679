import React, {Component} from "react";
import {MDBRow} from "mdbreact";
import {Button} from "@mui/material";
import Footer from "./footer";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";
import {useNavigate, useParams} from "react-router";
import {connect} from "react-redux";


class UserValidation extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            is_208: false,
            is_200: false,
            is_400: false,
            lang: this.props.params.lang,
        }
    }

    chooseLang() {

        if(this.state.lang === 'fa'){
            localStorage.setItem("lang", this.state.lang);
        }else {
            if(this.state.lang === 'en'){
                localStorage.setItem("lang", this.state.lang);
            }else {
                if(localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'fa');
                }
            }
        }

        var l = localStorage.getItem("lang")


        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {

        this.chooseLang()

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code', 1);
        const uid = urlParams.get('uid', 1);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code: code,
                uid: uid
            })
        };
        fetch('https://uharmonymatch.com/api/v1/validate-user/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if(response.status === 208) {
                    this.setState({
                        is_208: true,
                        is_200: false,
                        is_400: false
                    });
                }else {
                    if (response.status === 200) {
                        this.setState({
                            is_208: false,
                            is_200: true,
                            is_400: false
                        });
                    }else {
                        if (response.status === 400) {
                            this.setState({
                                is_208: false,
                                is_200: false,
                                is_400: true
                            });
                        }
                    }
                }

            })
            .catch((error) => {


            });
    }

    render() {
        if(this.state.is_208){
            return (
                <body className="text-right bg-body" dir="rtl">
                <div className={'container-fluid'}>

                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                        <div>
                            <img width={200} className={'img-fluid'} src="../images/success-reg.png" />
                        </div>
                    </MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                        <div>
                            <p>
                                {this.props.i18n.t('user_verified_already_msg')}
                            </p>
                        </div>

                    </MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                        <div>
                            <Button
                                className={'mt-3'}
                                onClick={(v) => this.backAfterSuccess(v)}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#C4C4C4",
                                    fontSize: "15px",
                                    color: "#fff",
                                    ":hover": {
                                        bgcolor: "#355070",
                                        color: "#fff",
                                    }
                                }}
                            >
                                {this.props.i18n.t('home_page_short')}
                            </Button>
                        </div>
                    </MDBRow>
                    <MDBRow className="mt-5 pt-4"></MDBRow>
                </div>
                </body>
            );
        }
        if(this.state.is_200){
            return (
                <body className="text-right bg-body" dir="rtl">
                <div className={'container-fluid'}>

                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                        <div>
                            <img width={200} className={'img-fluid'} src="../images/success-reg.png" />
                        </div>
                    </MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                        <div>
                            <p>
                                {this.props.i18n.t('user_verified_msg')}
                            </p>
                        </div>

                    </MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                        <div>
                            <Button
                                className={'mt-3'}
                                onClick={(v) => this.backAfterSuccess(v)}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#C4C4C4",
                                    fontSize: "15px",
                                    color: "#fff",
                                    ":hover": {
                                        bgcolor: "#355070",
                                        color: "#fff",
                                    }
                                }}
                            >
                                {this.props.i18n.t('home_page_short')}
                            </Button>
                        </div>
                    </MDBRow>
                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <Footer/>
                </div>
                </body>
            );
        }

        if(this.state.is_400){
            return (
                <body className="text-right bg-body" dir="rtl">
                <div className={'container-fluid'}>

                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                        <div>
                            <img width={200} className={'img-fluid'} src="../images/success-reg.png" />
                        </div>
                    </MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                        <div>
                            <p>
                                {this.props.i18n.t('user_verified_error_msg')}
                            </p>
                        </div>

                    </MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                        <div>
                            <Button
                                className={'mt-3'}
                                onClick={(v) => this.backAfterSuccess(v)}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#C4C4C4",
                                    fontSize: "15px",
                                    color: "#fff",
                                    ":hover": {
                                        bgcolor: "#355070",
                                        color: "#fff",
                                    }
                                }}
                            >
                                {this.props.i18n.t('home_page_short')}
                            </Button>
                        </div>
                    </MDBRow>
                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <Footer/>
                </div>
                </body>
            );
        }

        return (
            <div>
                Forbidden!
            </div>
        );


    }
    backAfterSuccess(v){
        window.location.href = '/'
    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}

export default connect()(withParams(withTranslation()(UserValidation)));

