import React, {Component} from "react";
import {MDBCard, MDBCardBody, MDBCol, MDBRow} from "mdbreact";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";
import {toast, ToastContainer} from "react-toastify";
import RegisterForm from "./register_form";
import {validate} from "react-email-validator";
import Footer from "./footer";
import Nav from "./NavBar";
import {BrowserView, MobileView} from "react-device-detect";
import {useParams} from "react-router";
import {connect} from "react-redux";
import {Button} from "@mui/material";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";


class Authentication extends Component{

    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            username: null,
            password: null,
            isLogin: false,
            needRegister: false,
            isBack: false,
            isResetPassword: false,
            lang: this.props.params.lang,
        }
        this.usernameRef = React.createRef();
        this.passwordRef = React.createRef();

    }

    chooseLang() {
        if(this.state.lang === 'fa'){
            localStorage.setItem("lang", this.state.lang);
        }else {
            if(this.state.lang === 'en'){
                localStorage.setItem("lang", this.state.lang);
            }else {
                if(localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'fa');
                }
            }
        }

        var l = localStorage.getItem("lang")
        this.languageChangeListener = () =>{
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {
        this.chooseLang()
        if(localStorage.getItem("profile") == null){
            this.getUserProfile()
        }
    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://uharmonymatch.com/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })

            .then((response) => {
                if(response.status === 200) {
                    localStorage.setItem('profile', JSON.stringify({
                        is_registration_complete: response.data.is_registration_complete,
                        is_verified: response.data.is_verified
                    }));
                    this.props.user_profile({
                        is_registration_complete: response.data.is_registration_complete,
                        is_verified: response.data.is_verified
                    })
                    this.setState({
                        username: null,
                        password: null,
                        isLogin: false,
                        needRegister: false,
                        isBack: false,
                        isResetPassword: false
                    });
                }

            })
            .catch((error) => {


            });
    }

    mobileView(){
        if (this.state.isResetPassword) {
            return (
                <body className="text-right bg-body" dir="rtl">
                <div className={'container-fluid'}>
                    <ToastContainer/>
                    <Nav/>
                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <MDBRow className={'m-1 text-center align-items-center align-self-center justify-content-md-center'}>
                        <MDBCard  alighment="center">
                            <MDBCardBody>
                                <div className={'text-right m-1'} style={{fontWeight: 'bold'}}>
                                    <label >{this.props.i18n.t('forget_password')}</label>
                                </div>

                                <div className="form-check p-2 text-right ">
                                    <label >{this.props.i18n.t('email_address')}</label>
                                    <input type="email" id="form12" name="username" className="form-control"
                                           onChange={(v) => this.usernameInputValue(v)} />

                                </div>

                                <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                    <div>
                                        <button className="btn w-50 mt-3" style={{backgroundColor: '#355070', color: '#fff'}} onClick={(v) => this.resetPasswordSubmit(v)}>
                                            {this.props.i18n.t('contact_us_form_send_btn')}
                                        </button>
                                    </div>
                                </MDBRow>

                            </MDBCardBody>
                        </MDBCard>
                    </MDBRow>
                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <Footer
                        isEnglish={this.props.i18n.language === 'en'}
                    />
                </div>
                </body>
            );
        }
        if (this.state.isBack) {
            return (
                this.setState({
                    isLogin: false,
                    needRegister: false
                })
            )
        }
        if (!this.state.isLogin && !this.state.needRegister) {
            if(this.props.i18n.language === 'fa') {
                return (
                    <body className="text-right bg-body" dir="rtl">
                    <div className={'container-fluid'}>
                        <ToastContainer/>
                        <Nav/>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow
                            className={'m-1 text-center align-items-center align-self-center justify-content-md-center'}>

                            <MDBCard alighment="center">
                                <MDBCardBody>
                                    <div className={'text-right m-1'} style={{fontWeight: 'bold'}}>
                                        <label>{this.props.i18n.t('user_panel_title')}</label>
                                    </div>

                                    <div className="form-check p-2 text-right ">
                                        <label>{this.props.i18n.t('email_address')}</label>
                                        <input type="email" id="form12" ref={this.usernameRef} name="username" className="form-control"
                                               onChange={(v) => this.usernameInputValue(v)}/>

                                    </div>
                                    <div className="form-check p-2 text-right">
                                        <label>{this.props.i18n.t('password_title')}</label>
                                        <input type="password" id="form12" ref={this.passwordRef} name='password' className="form-control"
                                               onChange={(v) => this.passwordInputValue(v)}/>
                                    </div>

                                    <div>
                                        <label
                                            style={{fontSize: '10px'}}>{this.props.i18n.t('remember_password_title')}</label>

                                        <button className={'btn btn-link'} onClick={(v) => this.resetPassword(v)}
                                                style={{fontSize: '10px'}}>{this.props.i18n.t('remember_password_btn')}</button>

                                    </div>

                                    <MDBRow
                                        className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                        <div>
                                            <button className="btn w-50 mt-3"
                                                    style={{backgroundColor: '#355070', color: '#fff'}}
                                                    onClick={(v) => this.loginClick(v)}>
                                                {this.props.i18n.t('sign_in_title')}
                                            </button>
                                        </div>
                                    </MDBRow>

                                </MDBCardBody>
                            </MDBCard>
                            <div className={'mt-3'}>
                                <label style={{
                                    fontSize: '15px',
                                    fontWeight: 'bold'
                                }}>{this.props.i18n.t('register_an_account')}</label>

                                <button onClick={(v) => this.registerClick(v)} style={{
                                    fontSize: '15px',
                                    marginRight: '5px',
                                    border: 'none',
                                    background: 'none',
                                    color: 'blue',
                                    fontWeight: 'bold'
                                }}>{this.props.i18n.t('register_btn')}</button>

                                <label style={{
                                    fontSize: '15px',
                                    fontWeight: 'bold'
                                }}>{this.props.i18n.t('doing_title')}</label>

                            </div>

                        </MDBRow>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <Footer
                            isEnglish={this.props.i18n.language === 'en'}
                        />
                    </div>
                    </body>

                );
            }else {
                return (
                    <body className="text-left bg-body ltr" dir="ltr">
                    <div className={'container-fluid'}>
                        <ToastContainer/>
                        <Nav/>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow
                            className={'m-1 text-center align-items-center align-self-center justify-content-md-center'}>

                            <MDBCard alighment="center">
                                <MDBCardBody>
                                    <div className={'text-left m-1'} style={{fontWeight: 'bold'}}>
                                        <label>{this.props.i18n.t('user_panel_title')}</label>
                                    </div>

                                    <div className="form-check p-2 text-left ">
                                        <label>{this.props.i18n.t('email_address')}</label>
                                        <input type="email" id="form12" ref={this.usernameRef} name="username" className="form-control"
                                               onChange={(v) => this.usernameInputValue(v)}/>

                                    </div>
                                    <div className="form-check p-2 text-left">
                                        <label>{this.props.i18n.t('password_title')}</label>
                                        <input type="password" id="form12" ref={this.passwordRef} name='password' className="form-control"
                                               onChange={(v) => this.passwordInputValue(v)}/>
                                    </div>

                                    <div>
                                        <label
                                            style={{fontSize: '10px'}}>{this.props.i18n.t('remember_password_title')}</label>

                                        <button className={'btn btn-link'} onClick={(v) => this.resetPassword(v)}
                                                style={{fontSize: '10px'}}>{this.props.i18n.t('remember_password_btn')}</button>

                                    </div>

                                    <MDBRow
                                        className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                        <div>
                                            <button className="btn w-50 mt-3"
                                                    style={{backgroundColor: '#355070', color: '#fff'}}
                                                    onClick={(v) => this.loginClick(v)}>
                                                {this.props.i18n.t('sign_in_title')}
                                            </button>
                                        </div>
                                    </MDBRow>

                                </MDBCardBody>
                            </MDBCard>
                            <div className={'mt-3'}>
                                <label style={{
                                    fontSize: '15px',
                                    fontWeight: 'bold'
                                }}>{this.props.i18n.t('register_an_account')}</label>

                                <button onClick={(v) => this.registerClick(v)} style={{
                                    fontSize: '15px',
                                    marginRight: '5px',
                                    border: 'none',
                                    background: 'none',
                                    color: 'blue',
                                    fontWeight: 'bold'
                                }}>{this.props.i18n.t('register_btn')}</button>

                                <label style={{
                                    fontSize: '15px',
                                    fontWeight: 'bold'
                                }}>{this.props.i18n.t('doing_title')}</label>

                            </div>

                        </MDBRow>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <Footer
                            isEnglish={this.props.i18n.language === 'en'}
                        />
                    </div>
                    </body>

                );
            }
        } else {
            if (this.state.needRegister) {
                return (
                    <RegisterForm/>
                );
            }
        }

    }

    desktopView() {
        if(this.props.i18n.language === 'fa') {
            if (this.state.isResetPassword) {
                return (
                    <body className="text-right bg-body ltr" dir="ltr">
                    <ToastContainer/>
                    <Nav/>
                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                        <MDBCol size={"5"} className="justify-content-center align-items-center h-100 text-center m-1">
                            <MDBCard alighment="center">
                                <MDBCardBody>
                                    <div className={'text-right m-1'} style={{fontWeight: 'bold'}}>
                                        <label>{this.props.i18n.t('forget_password')}</label>
                                    </div>

                                    <div className="form-check p-2 text-right "
                                         style={{marginRight: '35px', marginLeft: '35px'}}>
                                        <label>{this.props.i18n.t('email_address')}</label>
                                        <input type="email" id="form12" name="username" className="form-control"
                                               onChange={(v) => this.usernameInputValue(v)}/>

                                    </div>

                                    <MDBRow
                                        className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                        <div>
                                            <button className="btn w-25 mt-3"
                                                    style={{backgroundColor: '#C4C4C4', color: '#fff'}}
                                                    onClick={(v) => this.resetPasswordSubmit(v)}>
                                                {this.props.i18n.t('contact_us_form_send_btn')}
                                            </button>
                                        </div>
                                    </MDBRow>

                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol size={"3"} className={'m-4'}>
                            <img className={'img-fluid'} src="../images/login-1.png"/>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <Footer
                        isEnglish={this.props.i18n.language === 'en'}
                    />
                    </body>
                );
            }
            if (this.state.isBack) {
                return (
                    this.setState({
                        isLogin: false,
                        needRegister: false
                    })
                )
            }
            if (!this.state.isLogin && !this.state.needRegister) {
                return (
                    <body className="text-right bg-body" dir="rtl">
                    <div className={'container-fluid'}>
                        <ToastContainer/>
                        <Nav/>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center mt-5'}>
                            <MDBCol size={"5"}
                                    className="justify-content-center align-items-center h-100 text-center m-1">
                                <MDBCard alighment="center">
                                    <MDBCardBody>
                                        <div className={'text-right m-1'} style={{fontWeight: 'bold'}}>
                                            <label>{this.props.i18n.t('user_panel_title')}</label>
                                        </div>

                                        <div className="form-check p-2 text-right align-items-center align-content-center" align={'center'} style={{marginRight: '20px'}}>
                                            <label style={{paddingRight: '2.5em'}}>{this.props.i18n.t('email_address')}</label>
                                            <center>
                                                <input type="email" id="form12" ref={this.usernameRef} name="username" className="form-control align-content-center"
                                                       style={{width: '85%'}}
                                                       onChange={(v) => this.usernameInputValue(v)}/>
                                            </center>
                                        </div>
                                        <div className="form-check p-2 text-right align-items-center align-content-center" align={'center'} style={{marginRight: '20px'}}>
                                            <label style={{paddingRight: '2.5em'}}>{this.props.i18n.t('password_title')}</label>
                                            <center>
                                                <input type="password" id="form12" ref={this.passwordRef} name='password' className="form-control"
                                                       style={{width: '85%'}}
                                                       onChange={(v) => this.passwordInputValue(v)}/>
                                            </center>
                                        </div>

                                        <div>
                                            <label
                                                style={{fontSize: '10px'}}>{this.props.i18n.t('remember_password_title')}

                                                <span onClick={(v) => this.resetPassword(v)} style={{
                                                    fontSize: '10px',
                                                    marginRight: '20px',
                                                    color: 'blue',
                                                    cursor: 'pointer'
                                                }}>{this.props.i18n.t('remember_password_btn')}</span>
                                            </label>

                                        </div>

                                        <MDBRow
                                            className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                            <div>
                                                <Button
                                                    className={'w-25 mt-3'}
                                                    onClick={(v) => this.loginClick(v)}
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: "#C4C4C4",
                                                        fontSize: "15px",
                                                        fontFamily: 'IRANSansX',
                                                        color: "#fff",
                                                        ":hover": {
                                                            bgcolor: "#355070",
                                                            color: "#fff",
                                                        }
                                                    }}
                                                >
                                                    {this.props.i18n.t('sign_in_title')}
                                                </Button>

                                            </div>
                                        </MDBRow>

                                    </MDBCardBody>
                                </MDBCard>
                                <div className={'mt-3'}>
                                    <label style={{
                                        fontSize: '15px',
                                        fontWeight: 'bold'
                                    }}>{this.props.i18n.t('register_an_account')}</label>

                                    <button onClick={(v) => this.registerClick(v)} style={{
                                        fontSize: '15px',
                                        marginRight: '5px',
                                        border: 'none',
                                        background: 'none',
                                        fontFamily: 'IRANSansX',
                                        color: 'blue',
                                        fontWeight: 'bold'
                                    }}>{this.props.i18n.t('register_btn')}</button>

                                    <label style={{
                                        fontSize: '15px',
                                        fontWeight: 'bold'
                                    }}>{this.props.i18n.t('doing_title')}</label>

                                </div>
                            </MDBCol>

                            <MDBCol size={"3"} className={'m-4'}>
                                <img className={'img-fluid'} src="../images/login-1.png"/>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow className="mt-3 pt-4"></MDBRow>
                        <Footer
                            isEnglish={this.props.i18n.language === 'en'}
                        />
                    </div>
                    </body>

                );
            } else {
                if (this.state.needRegister) {
                    return (
                        <RegisterForm/>
                    );
                }

            }
        }else {
            if (this.state.isResetPassword) {
                return (
                    <body className="text-right bg-body" dir="rtl">
                    <ToastContainer/>
                    <Nav/>
                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center ltr'}>
                        <MDBCol size={"5"} className="justify-content-center align-items-center h-100 text-center m-1">
                            <MDBCard alighment="center">
                                <MDBCardBody>
                                    <div className={'text-left m-1'} style={{fontWeight: 'bold'}}>
                                        <label>{this.props.i18n.t('forget_password')}</label>
                                    </div>

                                    <div className="form-check p-2 text-left "
                                         style={{marginRight: '35px', marginLeft: '35px'}}>
                                        <label>{this.props.i18n.t('email_address')}</label>
                                        <input type="email" id="form12" name="username" className="form-control"
                                               onChange={(v) => this.usernameInputValue(v)}/>

                                    </div>

                                    <MDBRow
                                        className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                        <div>
                                            <button className="btn w-25 mt-3"
                                                    style={{backgroundColor: '#C4C4C4', color: '#fff'}}
                                                    onClick={(v) => this.resetPasswordSubmit(v)}>
                                                {this.props.i18n.t('contact_us_form_send_btn')}
                                            </button>
                                        </div>
                                    </MDBRow>

                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol size={"3"} className={'m-4'}>
                            <img className={'img-fluid'} src="../images/login-1.png"/>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <Footer
                        isEnglish={this.props.i18n.language === 'en'}
                    />
                    </body>
                );
            }
            if (this.state.isBack) {
                return (
                    this.setState({
                        isLogin: false,
                        needRegister: false
                    })
                )
            }
            if (!this.state.isLogin && !this.state.needRegister) {
                return (
                    <body className="text-right bg-body ltr" dir="ltr">
                    <div className={'container-fluid'}>
                        <ToastContainer/>
                        <Nav/>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center mt-5'}>
                            <MDBCol size={"5"}
                                    className="justify-content-center align-items-center h-100 text-center m-1">
                                <MDBCard alighment="center">
                                    <MDBCardBody>
                                        <div className={'text-left m-1'} style={{fontWeight: 'bold'}}>
                                            <label>{this.props.i18n.t('user_panel_title')}</label>
                                        </div>

                                        <div className="form-check p-2 text-left "
                                             style={{marginLeft: '20px'}}>
                                            <label style={{paddingLeft: '0.6em'}}>{this.props.i18n.t('email_address')}</label>
                                            <input type="email"
                                                   style={{width: '85%'}}
                                                   id="form12" name="username" className="form-control"
                                                   onChange={(v) => this.usernameInputValue(v)}/>

                                        </div>
                                        <div className="form-check p-2 text-left"  style={{marginLeft: '20px'}}>
                                            <label style={{paddingLeft: '0.6em'}}>{this.props.i18n.t('password_title')}</label>
                                            <input type="password" id="form12" name='password' className="form-control"
                                                   style={{width: '85%'}}
                                                   onChange={(v) => this.passwordInputValue(v)}/>
                                        </div>

                                        <div>
                                            <label
                                                style={{fontSize: '10px'}}>{this.props.i18n.t('remember_password_title')}

                                                <span onClick={(v) => this.resetPassword(v)} style={{
                                                    fontSize: '10px',
                                                    marginRight: '20px',
                                                    color: 'blue',
                                                    cursor: 'pointer'
                                                }}>{this.props.i18n.t('remember_password_btn')}</span>
                                            </label>

                                        </div>

                                        <MDBRow
                                            className={'text-center align-items-center align-self-center justify-content-md-center'}>
                                            <div>
                                                <Button
                                                    className={'w-25 mt-3'}
                                                    onClick={(v) => this.loginClick(v)}
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: "#C4C4C4",
                                                        fontSize: "15px",
                                                        fontFamily: 'IRANSansX',
                                                        color: "#fff",
                                                        ":hover": {
                                                            bgcolor: "#355070",
                                                            color: "#fff",
                                                        }
                                                    }}
                                                >
                                                    {this.props.i18n.t('sign_in_title')}
                                                </Button>

                                            </div>
                                        </MDBRow>

                                    </MDBCardBody>
                                </MDBCard>
                                <div className={'mt-3'}>
                                    <label style={{
                                        fontSize: '15px',
                                        fontWeight: 'bold'
                                    }}>{this.props.i18n.t('register_an_account')}</label>

                                    <button onClick={(v) => this.registerClick(v)} style={{
                                        fontSize: '15px',
                                        marginRight: '5px',
                                        border: 'none',
                                        background: 'none',
                                        color: 'blue',
                                        fontWeight: 'bold'
                                    }}>{this.props.i18n.t('register_btn')}</button>

                                    <label style={{
                                        fontSize: '15px',
                                        fontWeight: 'bold'
                                    }}>{this.props.i18n.t('doing_title')}</label>

                                </div>
                            </MDBCol>
                            <MDBCol size={"3"} className={'m-4'}>
                                <img className={'img-fluid'} src="../images/login-1.png"/>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow className="mt-3 pt-4"></MDBRow>
                        <Footer
                            isEnglish={this.props.i18n.language === 'en'}
                        />
                    </div>
                    </body>

                );
            } else {
                if (this.state.needRegister) {
                    return (
                        <RegisterForm/>
                    );
                }

            }
        }
    }

    render() {
        if(localStorage.getItem('token') !== null) {
            localStorage.removeItem('token')
            this.setState({isLogin: false, needRegister: false})
        }
        return (
            <div>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        );

    }

    usernameInputValue(value){
        this.state.username = value.target.value;
        try {
            this.usernameRef.current.classList.remove('input-error');
        }catch{
            
        }


    }

    passwordInputValue(value){
        this.state.password = value.target.value;
        this.passwordRef.current.classList.remove('input-error');
    }

    loginClick(st){

        if (this.state.username === null && this.state.password === null){
            this.usernameRef.current.focus();
            this.usernameRef.current.classList.add('input-error');
            this.passwordRef.current.classList.add('input-error');
            toast.error(this.props.i18n.t('email_password_are_empty_toast'), {
                position: toast.POSITION.TOP_LEFT
            });
        }else if (this.state.username === null) {
            this.usernameRef.current.focus();
            this.usernameRef.current.classList.add('input-error');
            toast.error(this.props.i18n.t('email_is_empty_toast'), {
                position: toast.POSITION.TOP_LEFT
            });
        }else if (this.state.password === null) {
            this.passwordRef.current.focus();
            this.passwordRef.current.classList.add('input-error');
            toast.error(this.props.i18n.t('password_is_empty_toast'), {
                position: toast.POSITION.TOP_LEFT
            });   
        
         }else{
            if(validate(this.state.username)) {
                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({username: this.state.username, password: this.state.password})
                };
                fetch('https://uharmonymatch.com/api/token/', requestOptions)
                    .then((response) => {
                        if(!response.ok) throw response.status;
                        else return response.json();
                    })
                    .then(json => {
                    if(json['access'] !== undefined) {
                        localStorage.setItem('token', json['access']);
                        window.location.href = "/my-profile";
                    }
                })
                    .catch((error) => {
                        if (error === 401) {
                            toast.error(this.props.i18n.t('user_not_found_error_toast'), {
                                position: toast.POSITION.TOP_LEFT
                            });
                        }else {
                            if (error === 405) {
                                toast.error(this.props.i18n.t('account_is_not_activate'), {
                                    position: toast.POSITION.TOP_LEFT
                                });
                            }
                        }

                    });
            }else {
                this.usernameRef.current.focus();
                this.usernameRef.current.classList.add('input-error');
                toast.error(this.props.i18n.t('email_validation_toast'), {
                    position: toast.POSITION.TOP_LEFT
                });
            }
        }

    }
    registerClick(st) {
        this.setState({needRegister: true})
    }

    resetPassword(st){
        this.setState({
            isResetPassword: true,
            needRegister: false,
            isBack: false
        })
    }

    resetPasswordSubmit(st){
        if(validate(this.state.username)) {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email: this.state.username})
            };
            fetch('https://uharmonymatch.com/api/v1/reset-password/', requestOptions)
                .then((response) => {
                    if (response.status === 200) {
                        toast.info(this.props.i18n.t('new_password_was_sent_to_you_toast'), {
                            position: toast.POSITION.TOP_LEFT
                        });
                        this.setState({needRegister: false, isResetPassword: false, isBack: false})
                    } else {
                        if (response.status === 404) {
                            toast.error(this.props.i18n.t('user_not_found_error_toast'), {
                                position: toast.POSITION.TOP_LEFT
                            });
                        }else {
                            toast.error(this.props.i18n.t('email_validation_toast'), {
                                position: toast.POSITION.TOP_LEFT
                            });
                        }
                    }
                });
        }
    }

}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function  mapState(state){
    return {
        user_profile: state.user_profile
    }
}

const updateProfile =(dispatch)=>{
    return{
        user_profile: (item)=>{ dispatch({type: 'UPDATE_PROFILE', item}) }
    }
}

export default connect(mapState, updateProfile)(withParams(withTranslation()(Authentication)));