import React, {Component} from "react";


import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import {MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBRow} from "mdbreact";
import Footer from "./footer";
import Nav from "./NavBar";
import YouTube from "react-youtube";
import {useParams} from "react-router";
import {connect} from "react-redux";
import NewSlider from "./newSlider";
import {BrowserView, MobileView} from "react-device-detect";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";
import ErrorToGetData from "./ErrorToGetData";


class Home_page extends Component{

    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            is_block: false,
            lang: this.props.params.lang,
        }

        this.i18n = this.props.i18n;

    }


    chooseLang() {
        if(this.state.lang === 'fa'){
            localStorage.setItem("lang", this.state.lang);
        }else {
            if(this.state.lang === 'en'){
                localStorage.setItem("lang", this.state.lang);
            }else {
                if(localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'fa');
                }
            }
        }


        var l = localStorage.getItem("lang")
        this.languageChangeListener = () =>{
            fetch('https://uharmonymatch.com/api/v1/main-page/?lang='+this.i18n.language)
                .then(res => res.json())
                .then(json => {
                    this.getUserProfile(json)

                });


        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            // alert(l)
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }

        this.get_main_data(l);
    }


    componentDidMount() {
        this.chooseLang()


    }

    get_main_data(l) {
        fetch('https://uharmonymatch.com/api/v1/main-page/?lang=' + l)
            .then(res => res.json())
            .then(json => {
                this.getUserProfile(json)

            });
    }

    getUserProfile(data){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://uharmonymatch.com/api/v1/profile/', requestOptions)
            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                localStorage.setItem('profile', JSON.stringify({
                    is_registration_complete: response.data.is_registration_complete,
                    is_seen_videos: response.data.is_seen_videos,
                    is_verified: response.data.is_verified
                }));
                this.props.user_profile({
                    is_registration_complete: response.data.is_registration_complete,
                    is_seen_videos: response.data.is_seen_videos,
                    is_verified: response.data.is_verified
                })
                this.setState({data: data});
            })
            .catch((error) => {
                if (error === 401) {
                    this.setState({data: data})
                } else {
                    if(error == 403) {
                        this.setState({data: data})
                    }else {
                        if (error == 429) {
                            this.setState({data: data})
                        }
                    }
                }

            });
    }

    mobileView(){
        if(this.state.is_block){
            return (
                <body className="text-right bg-body" dir="rtl">
                <Nav/>
                <MDBRow className="mt-5 pt-4"></MDBRow>
                <MDBRow className="justify-content-center align-items-center h-100 text-center m-1" >
                    <MDBCard alighment="center">
                        <MDBCardBody>
                            <MDBCardTitle>خطا</MDBCardTitle>
                            <MDBCardText>
                                {this.props.i18n.t('your_account_is_suspended_error')}
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBRow>
                <Footer
                    isEnglish={false}
                />
                </body>
            );
        }

        if(this.state.data !== null) {
            const opts = {
                height: '230',
                width: '270',
                playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 1,
                },
            };
            return this.getMainPageDataMobile(opts);
        }
        return (
            <div>
                {/*<ErrorToGetData/>*/}
            </div>
        );
    }

    getMainPageDataMobile(opts) {
        if(this.i18n.language === 'fa') {
            return (
                <div className="text-right container-fluid" dir="rtl">
                    <Nav/>
                    <MDBRow>
                        <NewSlider sliders={this.state.data.sliders}/>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 ">
                        <div className="rounded p-3">

                            <MDBRow className={'align-self-center p-1'}>

                                <label className={'mb-4'}
                                       style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_main}</label>

                                <div style={{fontSize: '15px', textAlign: 'justify'}}
                                     dangerouslySetInnerHTML={{__html: this.state.data.desc.description_main}}/>

                            </MDBRow>

                            <MDBRow>

                                <YouTube videoId="Z6EGhTawlO8" opts={opts} className={'p-0'}/>
                                {/*<YouTube videoId="B5fBvRS42sU" opts={opts} className={'p-0'}/>*/}

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4"
                            style={{backgroundColor: '#EEEEEE'}}>
                        <div className="rounded p-1">

                            <MDBRow className={'align-self-center'}>

                                <label className={'mb-4'}
                                       style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_1}</label>

                                <div style={{fontSize: '15px', textAlign: 'justify'}}
                                     dangerouslySetInnerHTML={{__html: this.state.data.desc.description_1}}/>

                            </MDBRow>
                            <MDBRow className={'mt-3'}>
                                <MDBRow>
                                    <MDBCol className={'text-left'}>
                                        <img className={'img-fluid'} src="images/s-2-2.png"/>
                                    </MDBCol>
                                    <MDBCol className={'text-right'}>
                                        <img className={'img-fluid'} src="images/s-2-1.png"/>
                                    </MDBCol>
                                </MDBRow>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4">
                        <div className="rounded p-1">
                            <MDBRow className={'p-3'}>
                                <MDBCol>
                                    <MDBRow>
                                        <MDBCol className={'text-left'}>
                                            <img className={'img-fluid'} src="images/s-3-1.png"/>
                                        </MDBCol>
                                        <MDBCol className={'text-right'}>
                                            <img className={'img-fluid'} src="images/s-3-2.png"/>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className={'mt-3'}>

                                <label style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_2}</label>

                                <div style={{fontSize: '15px', textAlign: 'justify'}}
                                     dangerouslySetInnerHTML={{__html: this.state.data.desc.description_2}}/>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4"
                            style={{backgroundColor: '#EEEEEE'}}>
                        <div className="rounded p-1">
                            <MDBRow>


                                <label style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_3}</label>

                                <div style={{fontSize: '15px', textAlign: 'justify'}}
                                     dangerouslySetInnerHTML={{__html: this.state.data.desc.description_3}}/>

                            </MDBRow>
                            <MDBRow>
                                <MDBCol className={'align-self-center mt-3'}>
                                    <img className={'img-fluid'} src="images/s-4.png"/>
                                </MDBCol>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4">
                        <div className="rounded p-1">
                            <MDBRow>
                                <img className={'img-fluid'} src="images/s-5.png"/>
                            </MDBRow>

                            <MDBRow className={'align-self-center'}>

                                <label style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_4}</label>

                                <div style={{fontSize: '15px', textAlign: 'justify'}}
                                     dangerouslySetInnerHTML={{__html: this.state.data.desc.description_4}}/>

                            </MDBRow>


                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4"
                            style={{backgroundColor: '#EEEEEE'}}>
                        <div className="rounded p-1">
                            <MDBRow>


                                <label style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_end}</label>
                                <div>
                                    <div style={{fontSize: '15px', textAlign: 'justify', fontWeight: 'bold'}}
                                         dangerouslySetInnerHTML={{__html: this.state.data.desc.description_end}}/>
                                </div>
                                <div className={'mt-3'}>
                                    <img className={'img-fluid'} src="images/s-end.png"/>
                                </div>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4">
                        <div className="rounded p-3">
                            <MDBRow className={'text-center'}>
                                <div className={'mt-5 text-center'}>
                                    <a href='/register' className='text-decoration-none'>
                                        <button className="btn" style={{background: '#FF686B', color: '#fff'}}
                                                type='submit'>
                                            <label>{this.i18n.t('register_now_btn')}</label>
                                        </button>
                                    </a>
                                </div>

                            </MDBRow>

                            <MDBRow className={'text-center mt-5'}>
                                <MDBRow className={'align-self-center p-3'}>
                                    <img className={'img-fluid'} src="images/Step-m-1.png"/>
                                </MDBRow>
                                <MDBRow className={'align-self-center p-3'}>
                                    <img className={'img-fluid'} src="images/Step-m-2.png"/>
                                </MDBRow>
                                <MDBRow className={'align-self-center p-3'}>
                                    <img className={'img-fluid'} src="images/Step-m-3.png"/>
                                </MDBRow>
                                <MDBRow className={'align-self-center p-3'}>
                                    <img className={'img-fluid'} src="images/Step-m-4.png"/>
                                </MDBRow>
                            </MDBRow>
                        </div>
                    </MDBRow>

                    <Footer
                        isEnglish={false}
                    />
                </div>
            );
        }else {
            return (
                <div className="text-right container-fluid" dir="rtl">
                    <Nav/>
                    <MDBRow>
                        <NewSlider sliders={this.state.data.sliders}/>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 ">
                        <div className="rounded p-3">

                            <MDBRow className={'align-self-center p-1'}>

                                <label className={'mb-4 ltr'}
                                       style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_main}</label>

                                <div className={'ltr'} style={{fontSize: '15px', textAlign: 'justify'}}
                                     dangerouslySetInnerHTML={{__html: this.state.data.desc.description_main}}/>

                            </MDBRow>

                            <MDBRow>

                                {/*<YouTube videoId="eni3M0-fpXo" opts={opts} className={'p-0'}/>*/}
                                <YouTube videoId="Ck_Z87sd_Jg" opts={opts} className={'p-0'}/>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4"
                            style={{backgroundColor: '#EEEEEE'}}>
                        <div className="rounded p-1">

                            <MDBRow className={'align-self-center'}>

                                <label className={'mb-4 ltr'}
                                       style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_1}</label>

                                <div className={'ltr'} style={{fontSize: '15px', textAlign: 'justify'}}
                                     dangerouslySetInnerHTML={{__html: this.state.data.desc.description_1}}/>

                            </MDBRow>
                            <MDBRow className={'mt-3'}>
                                <MDBRow>
                                    <MDBCol className={'text-left'}>
                                        <img className={'img-fluid'} src="images/s-2-2.png"/>
                                    </MDBCol>
                                    <MDBCol className={'text-right'}>
                                        <img className={'img-fluid'} src="images/s-2-1.png"/>
                                    </MDBCol>
                                </MDBRow>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4">
                        <div className="rounded p-1">
                            <MDBRow className={'p-3'}>
                                <MDBCol>
                                    <MDBRow>
                                        <MDBCol className={'text-left'}>
                                            <img className={'img-fluid'} src="images/s-3-1.png"/>
                                        </MDBCol>
                                        <MDBCol className={'text-right'}>
                                            <img className={'img-fluid'} src="images/s-3-2.png"/>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className={'mt-3'}>

                                <label className={'ltr'} style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_2}</label>

                                <div className={'ltr'} style={{fontSize: '15px', textAlign: 'justify'}}
                                     dangerouslySetInnerHTML={{__html: this.state.data.desc.description_2}}/>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4"
                            style={{backgroundColor: '#EEEEEE'}}>
                        <div className="rounded p-1">
                            <MDBRow>


                                <label className={'ltr'} style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_3}</label>

                                <div className={'ltr'} style={{fontSize: '15px', textAlign: 'justify'}}
                                     dangerouslySetInnerHTML={{__html: this.state.data.desc.description_3}}/>

                            </MDBRow>
                            <MDBRow>
                                <MDBCol className={'align-self-center mt-3'}>
                                    <img className={'img-fluid'} src="images/s-4.png"/>
                                </MDBCol>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4">
                        <div className="rounded p-1">
                            <MDBRow>
                                <img className={'img-fluid'} src="images/s-5.png"/>
                            </MDBRow>

                            <MDBRow className={'align-self-center'}>

                                <label className={'ltr'} style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_4}</label>

                                <div className={'ltr'} style={{fontSize: '15px', textAlign: 'justify'}}
                                     dangerouslySetInnerHTML={{__html: this.state.data.desc.description_4}}/>

                            </MDBRow>


                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4"
                            style={{backgroundColor: '#EEEEEE'}}>
                        <div className="rounded p-1">
                            <MDBRow>

                                <label className={'ltr'} style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_end}</label>
                                <div>
                                    <div className={'ltr'} style={{fontSize: '15px', textAlign: 'justify', fontWeight: 'bold'}}
                                         dangerouslySetInnerHTML={{__html: this.state.data.desc.description_end}}/>
                                </div>
                                <div className={'mt-3'}>
                                    <img className={'img-fluid'} src="images/s-end.png"/>
                                </div>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4">
                        <div className="rounded p-3">
                            <MDBRow className={'text-center'}>
                                <div className={'mt-5 text-center'}>
                                    <a href='/register' className='text-decoration-none'>
                                        <button className="btn" style={{background: '#FF686B', color: '#fff'}}
                                                type='submit'>
                                            <label>{this.i18n.t('register_now_btn')}</label>
                                        </button>
                                    </a>
                                </div>

                            </MDBRow>

                            <MDBRow className={'text-center mt-5 '}>
                                <MDBRow className={'align-self-center p-3'}>
                                    <img className={'img-fluid'} src="images/Step-m-1-English.png"/>
                                </MDBRow>
                                <MDBRow className={'align-self-center p-3'}>
                                    <img className={'img-fluid'} src="images/Step-m-2-English.png"/>
                                </MDBRow>
                                <MDBRow className={'align-self-center p-3'}>
                                    <img className={'img-fluid'} src="images/Step-m-3-English.png"/>
                                </MDBRow>
                                <MDBRow className={'align-self-center p-3'}>
                                    <img className={'img-fluid'} src="images/Step-m-4-English.png"/>
                                </MDBRow>
                            </MDBRow>
                        </div>
                    </MDBRow>

                    <Footer
                        isEnglish={true}
                    />
                </div>
            );
        }
    }

    desktopView(){
        if(this.state.is_block){
            return (
                <body className="text-right bg-body" dir="rtl">
                <Nav/>
                <MDBRow className="mt-5 pt-4"></MDBRow>
                <MDBRow className="justify-content-center align-items-center h-100 text-center m-1" >
                    <MDBCard alighment="center">
                        <MDBCardBody>
                            <MDBCardTitle>خطا</MDBCardTitle>
                            <MDBCardText>
                                حساب کاربری شما توسط ادمین مسدود شده است با پشتیبانی تماس حاصل فرمایید.
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBRow>
                <Footer
                    isEnglish={false}
                />
                </body>
            );
        }

        if(this.state.data !== null) {
            const opts = {
                height: '310',
                width: '510',
                playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 1,
                },
            };

            return this.getPageDataDesktop(opts);
        }
        return (
            <div>

            </div>
        );
    }

    getPageDataDesktop(opts) {
        if(this.i18n.language === 'fa') {
            return (
                <div className="text-right container-fluid" dir="rtl">
                    <Nav/>
                    <MDBRow>
                        <NewSlider sliders={this.state.data.sliders}/>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-4" style={{marginRight: '90px', marginLeft: '100px'}}>
                        <div className="rounded p-3">
                            <MDBRow>
                                <MDBCol>
                                    <YouTube videoId="Z6EGhTawlO8" opts={opts} />
                                    {/*<YouTube videoId="B5fBvRS42sU" opts={opts} />*/}
                                </MDBCol>
                                <MDBCol className={'align-self-center'}>

                                    <label className={'mb-3'}
                                           style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_main}</label>

                                    <div style={{fontSize: '15px', textAlign: 'justify'}}
                                         dangerouslySetInnerHTML={{__html: this.state.data.desc.description_main}}/>

                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center  p-4"
                            style={{backgroundColor: '#EEEEEE'}}>
                        <div className="rounded" >
                            <MDBRow style={{marginRight: '90px', marginLeft: '100px', paddingRight: '10px'}}>
                                <div className={'text-end'} >
                                    <img width={'30'} className={'img-fluid'} src="images/1c.png"/>
                                </div>
                                <MDBCol size={6}>
                                    <div className={'text-right'}>
                                        <label className={'mb-3'} style={{
                                            fontWeight: 'bold',
                                            textAlign: 'right',
                                            paddingRight: '50px'
                                        }}>{this.state.data.desc.description_title_1}</label>

                                        <div style={{fontSize: '15px', textAlign: 'justify', paddingRight: '50px'}}
                                             dangerouslySetInnerHTML={{__html: this.state.data.desc.description_1}}/>
                                    </div>

                                </MDBCol>
                                <MDBCol  size={6}>
                                    <MDBRow style={{marginRight: '210px'}}>
                                        <MDBCol className={'text-left'}>
                                            <img width={'150'} src="images/s-2-2.png"/>
                                        </MDBCol>
                                        <MDBCol className={'text-right'}>
                                            <img width={'150'} src="images/s-2-1.png"/>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center  p-4" style={{marginRight: '90px', marginLeft: '100px'}}>
                        <div className="rounded">
                            <MDBRow>

                                <MDBCol>

                                    <MDBRow>
                                        <MDBCol className={'text-right'} size={5}>
                                            <img width={'163'} src="images/s-3-1.png" style={{marginRight: '10px'}}/>
                                        </MDBCol>
                                        <MDBCol className={'text-right'} size={5}>
                                            <img width={'150'} src="images/s-3-2.png"/>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>

                                <MDBCol className={'align-self-center'}>
                                    <div className={'text-right'}>
                                        <img width={'30'} className={'img-fluid'} src="images/2c.png"/>
                                    </div>
                                    <label
                                        style={{fontWeight: 'bold', marginRight: '90px'}}>{this.state.data.desc.description_title_2}</label>

                                    <div style={{fontSize: '15px', textAlign: 'justify', marginRight: '30px'}}
                                         dangerouslySetInnerHTML={{__html: this.state.data.desc.description_2}}/>

                                </MDBCol>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center  p-4"
                            style={{backgroundColor: '#EEEEEE'}}>

                        <div className="rounded" >
                            <MDBRow style={{marginRight: '90px', marginLeft: '100px', paddingRight: '10px'}}>
                                <div className={'text-end'}>
                                    <img width={'30'} className={'img-fluid'} src="images/3c.png"/>
                                </div>
                                <MDBCol className={'align-self-top'}>

                                    <label style={{
                                        fontWeight: 'bold',
                                        paddingRight: '50px'
                                    }}>{this.state.data.desc.description_title_3}</label>

                                    <div style={{fontSize: '15px', textAlign: 'justify', paddingRight: '50px'}}
                                         dangerouslySetInnerHTML={{__html: this.state.data.desc.description_3}}/>

                                </MDBCol>
                                <MDBCol className={'align-self-center text-left'} size={6}>
                                    <div>
                                        <img height={170} src="images/s-4.png"/>
                                    </div>
                                </MDBCol>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-4" style={{marginRight: '90px', marginLeft: '100px'}}>
                        <div className="rounded">
                            <MDBRow>

                                <MDBCol size={6} className={'text-right'}>

                                    <img height={170} src="images/s-5.png" style={{marginRight: '30px'}}/>
                                </MDBCol>

                                <MDBCol className={'align-self-center'}>
                                    <div className={'text-right'}>
                                        <img width={'30'} className={'img-fluid'} src="images/4c.png"/>
                                    </div>
                                    <label
                                        style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_4}</label>

                                    <div style={{fontSize: '15px', textAlign: 'justify', marginRight: '30px'}}
                                         dangerouslySetInnerHTML={{__html: this.state.data.desc.description_4}}/>

                                </MDBCol>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4"
                            style={{backgroundColor: '#EEEEEE'}}>
                        <div className="rounded p-3">
                            <MDBRow style={{marginRight: '90px', marginLeft: '100px'}}>


                                <label style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_end}</label>
                                <div className={'p-5'}>
                                    <div style={{fontSize: '15px', textAlign: 'center', fontWeight: 'bold'}}
                                         dangerouslySetInnerHTML={{__html: this.state.data.desc.description_end}}/>
                                </div>
                                <div>
                                    <img height={'150'} src="images/s-end.png"/>
                                </div>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-4" style={{marginRight: '90px', marginLeft: '100px'}}>
                        <div className="rounded p-3">
                            <MDBRow className={'text-center'}>
                                <div className={'mt-5 text-center'}>
                                    <a href='/register' className='text-decoration-none'>
                                        <button className="btn" style={{background: '#FF686B', color: '#fff'}}
                                                type='submit'>
                                            <label>{this.i18n.t('register_now_btn')}</label>
                                        </button>
                                    </a>
                                </div>

                            </MDBRow>

                            <MDBRow className={'text-center mt-5 mb-5'}>
                                <MDBCol className={'align-self-center'}>
                                    <img width={'250'} src="images/Step-m-1.png"/>
                                </MDBCol>
                                <MDBCol className={'align-self-center'}>
                                    <img width={'250'} src="images/Step-m-2.png"/>
                                </MDBCol>
                                <MDBCol className={'align-self-center'}>
                                    <img width={'250'} src="images/Step-m-3.png"/>
                                </MDBCol>
                                <MDBCol className={'align-self-center'}>
                                    <img width={'250'} src="images/Step-m-4.png"/>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBRow>

                    <Footer
                        isEnglish={false}
                    />
                </div>
            );
        }else {
            return (
                <div className="text-right container-fluid" dir="rtl">
                    <Nav/>
                    <MDBRow>
                        <NewSlider sliders={this.state.data.sliders}/>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-4" style={{marginRight: '90px', marginLeft: '100px'}}>
                        <div className="rounded p-3">
                            <MDBRow>
                                <MDBCol>
                                    <YouTube videoId="Ck_Z87sd_Jg" opts={opts} className={'p-4'}/>
                                    {/*<YouTube videoId="eni3M0-fpXo" opts={opts} className={'p-4'}/>*/}
                                </MDBCol>
                                <MDBCol className={'align-self-center'}>

                                    <label className={'mb-3 ltr'}
                                           style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_main}</label>

                                    <div className={'ltr'} style={{fontSize: '15px', textAlign: 'justify'}}
                                         dangerouslySetInnerHTML={{__html: this.state.data.desc.description_main}}/>

                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-4"
                            style={{backgroundColor: '#EEEEEE'}}>
                        <div className="rounded">
                            <MDBRow style={{marginRight: '90px', marginLeft: '100px', paddingRight: '10px'}}>
                                <div className={'text-end'}>
                                    <img width={'30'} className={'img-fluid'} src="images/1c.png"/>
                                </div>
                                <MDBCol size={6}>
                                    <div className={'text-left'}>
                                        <label className={'mb-3 ltr'} style={{
                                            fontWeight: 'bold',
                                            textAlign: 'right',
                                            paddingRight: '50px'
                                        }}>{this.state.data.desc.description_title_1}</label>

                                        <div className={'ltr'} style={{fontSize: '15px', textAlign: 'justify', paddingRight: '50px'}}
                                             dangerouslySetInnerHTML={{__html: this.state.data.desc.description_1}}/>
                                    </div>

                                </MDBCol>
                                <MDBCol size={6}>
                                    <MDBRow>
                                        <MDBCol className={'text-left'} >
                                            <img width={'150'} src="images/s-2-2.png" style={{marginRight: '210px'}}/>
                                        </MDBCol>
                                        <MDBCol className={'text-left'}>
                                            <img width={'150'} src="images/s-2-1.png"/>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-4" style={{marginRight: '90px', marginLeft: '100px'}}>
                        <div className="rounded p-3">
                            <MDBRow>

                                <MDBCol>

                                    <MDBRow>
                                        <MDBCol className={'text-right'} size={5}>
                                            <img width={'163'} src="images/s-3-1.png" style={{marginRight: '10px'}}/>
                                        </MDBCol>
                                        <MDBCol className={'text-right'} size={5}>
                                            <img width={'150'} src="images/s-3-2.png"/>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>

                                <MDBCol className={'align-self-center'}>
                                    <div className={'text-right'}>
                                        <img width={'30'} className={'img-fluid'} src="images/2c.png"/>
                                    </div>
                                    <label
                                        style={{fontWeight: 'bold', marginRight: '90px'}}>{this.state.data.desc.description_title_2}</label>

                                    <div className={'ltr'} style={{fontSize: '15px', textAlign: 'justify', marginRight: '30px'}}
                                         dangerouslySetInnerHTML={{__html: this.state.data.desc.description_2}}/>

                                </MDBCol>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4"
                            style={{backgroundColor: '#EEEEEE'}}>

                        <div className="rounded">
                            <MDBRow style={{marginRight: '90px', marginLeft: '100px', paddingRight: '10px'}}>
                                <div className={'text-end'}>
                                    <img width={'30'} className={'img-fluid'} src="images/3c.png"/>
                                </div>
                                <MDBCol className={'align-self-top'}>

                                    <label

                                        style={{
                                            fontWeight: 'bold',
                                            paddingRight: '50px'
                                        }}>{this.state.data.desc.description_title_3}</label>

                                    <div className={'ltr text-left'} style={{fontSize: '15px', textAlign: 'justify', paddingRight: '50px'}}
                                         dangerouslySetInnerHTML={{__html: this.state.data.desc.description_3}}/>

                                </MDBCol>
                                <MDBCol className={'align-self-center text-left'} size={6}>
                                    <div>
                                        <img height={170} src="images/s-4.png"/>
                                    </div>
                                </MDBCol>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-4" style={{marginRight: '90px', marginLeft: '100px'}}>
                        <div className="rounded ">
                            <MDBRow>

                                <MDBCol size={6} className={'text-right'}>
                                    <img height={170} src="images/s-5.png" style={{marginRight: '30px'}}/>
                                </MDBCol>

                                <MDBCol className={'align-self-center'}>
                                    <div className={'text-right'}>
                                        <img width={'30'} className={'img-fluid'} src="images/4c.png"/>
                                    </div>
                                    <label
                                        className={'ltr text-left'} style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_4}</label>

                                    <div className={'ltr text-left'} style={{fontSize: '15px', textAlign: 'justify', marginRight: '30px'}}
                                         dangerouslySetInnerHTML={{__html: this.state.data.desc.description_4}}/>

                                </MDBCol>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1 p-4"
                            style={{backgroundColor: '#EEEEEE'}}>
                        <div className="rounded">
                            <MDBRow style={{marginRight: '90px', marginLeft: '100px', paddingRight: '100px'}}>

                                <label className={'ltr'} style={{fontWeight: 'bold'}}>{this.state.data.desc.description_title_end}</label>
                                <div className={'p-5 ltr'}>
                                    <div style={{fontSize: '15px', textAlign: 'center', fontWeight: 'bold'}}
                                         dangerouslySetInnerHTML={{__html: this.state.data.desc.description_end}}/>
                                </div>
                                <div>
                                    <img height={'150'} src="images/s-end.png"/>
                                </div>

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-4" style={{marginRight: '90px', marginLeft: '100px'}}>
                        <div className="rounded p-3">
                            <MDBRow className={'text-center'}>
                                <div className={'mt-5 text-center'}>
                                    <a href='/register' className='text-decoration-none'>
                                        <button className="btn" style={{background: '#FF686B', color: '#fff'}}
                                                type='submit'>
                                            <label>{this.i18n.t('register_now_btn')}</label>
                                        </button>
                                    </a>
                                </div>

                            </MDBRow>

                            <MDBRow className={'text-center mt-5 mb-5 ltr'}>
                                <MDBCol className={'align-self-center'}>
                                    <img width={'250'} src="images/Step-m-1-English.png"/>
                                </MDBCol>
                                <MDBCol className={'align-self-center'}>
                                    <img width={'250'} src="images/Step-m-2-English.png"/>
                                </MDBCol>
                                <MDBCol className={'align-self-center'}>
                                    <img width={'250'} src="images/Step-m-3-English.png"/>
                                </MDBCol>
                                <MDBCol className={'align-self-center'}>
                                    <img width={'250'} src="images/Step-m-4-English.png"/>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBRow>

                    <Footer
                        isEnglish={true}
                    />
                </div>
            );
        }
    }

    render() {
        return(
            <div>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        )
    }
}


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function  mapState(state){
    return {
        user_profile: state.user_profile
    }
}

const updateProfile =(dispatch)=>{
    return{
        user_profile: (item)=>{ dispatch({type: 'UPDATE_PROFILE', item}) }
    }
}

export default connect(mapState, updateProfile)(withParams(withTranslation()(Home_page)));
